import Header1 from '../diffuseurs/Header1';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../../key';
import { useLocation } from "react-router-dom";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";



const CompteDA = () => {
  const [username, setUsername] = useState(''); // Store username dynamically
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();
  const userRoles = ["AUTEUR", "DIFFUSEUR"]; // Récupéré depuis l'authentification
  const location = useLocation();
  const [isAuteurOpen, setIsAuteurOpen] = useState(false);
  const [isDiffuseurOpen, setIsDiffuseurOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     try {
  //       const token = localStorage.getItem('token');
  //       if (!token) {
  //         navigate('/login'); 
  //         return;
  //       }

  //       const response = await fetch('http://localhost:8000/api/user', {
  //         // const response = await fetch('https://editions7.com/admin/public/api/user', {

  //         method: 'GET',
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //         },
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         setUsername(data.username);
  //         setUserRole(data.role); 
  //         setIsLoggedIn(true);
  //       } else {
  //         navigate('/login'); 
  //       }
  //     } catch (error) {
  //       console.error('Error fetching user details:', error);
  //       navigate('/login');
  //     }
  //   };

  //   fetchUserDetails();
  // }, [navigate]);
useEffect(() => {
  const fetchUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found. Redirecting to login.');
      navigate('/login');
      return;
    }

    try {
      // const response = await fetch('http://localhost:8000/api/user', {
       const response = await fetch(`${BASE_URL}/user`, {

        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('User data:', data);
        setUsername(data.username);
        setUserRole(data.role);
        setIsLoggedIn(true);
      } else {
        console.error('API Error Status:', response.status);
        navigate('/login');
      }
    } catch (error) {
      console.error('Network error:', error);
      navigate('/login');
    }
  };

  fetchUserDetails();
}, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
    navigate('/login');
  };

  useEffect(() => {
    const path = location.pathname;

    // Set dropdown open state and highlight selected item
    if (["/livres-auteurs", "/recherche-pro", "/devis","/ventes","stocks"].includes(path)) {
      setIsAuteurOpen(true);
      setSelectedItem(path);
    } else if (["/points", "/recherche-auteurs", "/mes-ventes-factures"].includes(path)) {
      setIsDiffuseurOpen(true);
      setSelectedItem(path);
    }
  }, [location.pathname]);

  return (
    <div className="bg-white min-h-screen">
      <Header1 />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
         {/* Sidebar */}
         <div className="bg-white shadow-md rounded-lg p-4">
  <ul className="space-y-4">
    {/* Fonctionnalités Globales */}
    <li className="group">
      <a
        href="/compte-auteur-diffuseur"
        className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 hover:rounded-md focus:border focus:border-green-500"
      >
        <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
          <IoMdArrowDropright className="text-green-500 h-6 w-6" />
        </span>
        Tableau de bord
      </a>
    </li>
    <li className="group">
      <a
        href="/informations"
        className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 hover:rounded-md focus:border focus:border-green-500"
      >
        <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
          <IoMdArrowDropright className="text-green-500 h-6 w-6" />
        </span>
        Mes Informations
      </a>
    </li>
    <li className="group">
      <a
        href="/payment"
        className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 hover:rounded-md focus:border focus:border-green-500"
      >
        <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
          <IoMdArrowDropright className="text-green-500 h-6 w-6" />
        </span>
        Facture d'abonnement
      </a>
    </li>

    {/* Dropdown pour les fonctionnalités Auteur */}
    {userRoles.includes("AUTEUR") && (
      <li className="group">
        <div
          className="font-bold text-gray-600 flex items-center cursor-pointer"
          onClick={() => setIsAuteurOpen(!isAuteurOpen)}
        >
          <IoMdArrowDropdown
            className={`h-6 w-6 transition-transform duration-200 ${
              isAuteurOpen ? "rotate-180" : ""
            }`}
          />
          <span className="ml-2">Fonctionnalités Auteur</span>
        </div>
        {isAuteurOpen && (
          <ul className="pl-6 mt-2 space-y-2">
            <li className="group">
              <a
                href="/livres-auteurs"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Mes livres
              </a>
            </li>
            <li className="group">
              <a
                href="/recherche-pro"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Trouver des professionnels
              </a>
            </li>
            <li className="group">
              <a
                href="/devis"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Devis Impression/Mes demandes Devis
              </a>
            </li>

            <li className="group">
              <a
                href="/ventes"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Mes Ventes
              </a>
            </li>
            <li className="group">
              <a
                href="/stocks"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Gestion de Stock
               </a>
            </li>
          </ul>
        )}
      </li>
    )}

    {/* Dropdown pour les fonctionnalités Diffuseur */}
    {userRoles.includes("DIFFUSEUR") && (
      <li className="group">
        <div
          className="font-bold text-gray-600 flex items-center cursor-pointer"
          onClick={() => setIsDiffuseurOpen(!isDiffuseurOpen)}
        >
          <IoMdArrowDropdown
            className={`h-6 w-6 transition-transform duration-200 ${
              isDiffuseurOpen ? "rotate-180" : ""
            }`}
          />
          <span className="ml-2">Fonctionnalités Diffuseur</span>
        </div>
        {isDiffuseurOpen && (
          <ul className="pl-6 mt-2 space-y-2">
            <li className="group">
              <a
                href="/points"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Gestion de points de vente
              </a>
            </li>
            <li className="group">
              <a
                href="/recherche-auteurs"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Trouver des auteurs
              </a>
            </li>
            <li className="group">
              <a
                href="/mes-ventes-factures"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Mes Ventes/Factures 
              </a>
            </li>
          </ul>
        )}
      </li>
    )}
  </ul>
</div>




          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <p className="text-lg mb-4">
                Bonjour <strong>{username || 'Utilisateur'}</strong> (vous n'êtes pas{' '}
                <strong>{username || 'Utilisateur'}</strong>?{' '}
                <span onClick={handleLogout} className="text-blue-600 cursor-pointer">
                  Déconnexion
                </span>
                )
              </p>
              <p className="text-sm mb-4">
                À partir du tableau de bord de votre compte, vous pouvez visualiser vos{' '}
                <a href="/recent-orders" className="text-blue-600">
                  commandes récentes
                </a>
                , gérer vos{' '}
                <a href="/delivery-addresses" className="text-blue-600">
                  adresses de livraison et de facturation
                </a>{' '}
                ainsi que{' '}
                <a href="/change-password" className="text-blue-600">
                  changer votre mot de passe
                </a>{' '}
                et{' '}
                <a href="/account-details" className="text-blue-600">
                  les détails de votre compte
                </a>
                .
              </p>
              <h2 className="text-md font-bold mb-4">
                L'objectif est de trouver des auteurs qui souhaitent passer par vous pour vendre leurs
                livres en points de ventes.
              </h2>
              <ol className="list-decimal pl-4 space-y-4">
                <li>
                  Trouver des auteurs dans la partie en haut du site : "Trouver des auteurs"
                </li>
                <li>Discuter avec les membres (auteurs)</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompteDA;
