import axios from 'axios';
const FormData = require("form-data");

class ClientSign {
    constructor() {
      this.BASE_URL = 'https://api-sandbox.yousign.app/v3';
      this.API_KEY = '3y7nSDd3dOlMfPz9XR7bvGZ5wrq2qxYS';
    }
  
    async request(endpoint = '', options = {}, headers = {}) {
      const url = `${this.BASE_URL}/${endpoint}`;
      const config = {
        url,
        headers: {
          Authorization: `Bearer ${this.API_KEY}`,
          ...headers
        },
        ...options
      };
  
      try {
        const res = await axios(config);
        return res.data;
      } catch (error) {
        console.error('API Error:', error);
        throw new Error(`Error on API call: ${error.message}`);
      }
    }
  
    // Initiate Signature Request
  initiateSignatureRequest = () => {
    const body = {
      name: "Signature request example",
      delivery_mode: 'email',
      timezone: 'Europe/Paris',
    };
    const options = {
      method: 'POST',
      data: JSON.stringify(body),
    };
    const headers = {
      'Content-type': 'application/json',
    };
    return this.request('signature_requests', options, headers);
  }

  // Upload Document
  uploadDocument = async (signatureRequestId) => {
    // Get the full URL of your PDF
    const pdfUrl = `${window.location.origin}/pdf/Contrat-EDITIONS7-DIFFUSEUR-V2.pdf`;
    const pdfResponse = await fetch(pdfUrl);
    const pdfBlob = await pdfResponse.blob();
    
    const form = new FormData();
    form.append('file', pdfBlob, 'Contrat EDITIONS 7 - DIFFUSEUR V2.pdf');
    form.append('nature', 'signable_document');
    form.append('parse_anchors', 'true');

    const options = {
      method: 'POST',
      data: form,
    };
    
    return this.request(`signature_requests/${signatureRequestId}/documents`, options);
  }

  // Add Signer
  addSigner = (signatureRequestId, documentId, signerInfo) => {
    const body = {
      info: {
        first_name: signerInfo.first_name || 'chaymae',
        last_name: signerInfo.last_name || 'farkouchi',
        email: signerInfo.email,
        phone_number: signerInfo.phone_number || '+33601234567', // Fallback if no phone provided
        locale: 'fr',
      },
      signature_level: 'electronic_signature',
      signature_authentication_mode: 'no_otp',
      fields: [
        {
          document_id: documentId,
          type: 'signature',
          page: 1,
          x: 77,
          y: 581,
        }
      ]
    };
    const options = {
      method: 'POST',
      data: JSON.stringify(body),
    };
    const headers = {
      'Content-type': 'application/json',
    };
    return this.request(`signature_requests/${signatureRequestId}/signers`, options, headers);
  }

  // Activate Signature Request
  activateSignatureRequest = (signatureRequestId) => {
    const options = {
      method: 'POST',
    }
    return this.request(`signature_requests/${signatureRequestId}/activate`, options);
  }
  }
  export default ClientSign;