import React, { useState, useEffect } from 'react';
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import Header1 from '../diffuseurs/Header1';
import BASE_URL from '../../key';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Stocks = () => {
  const [username, setUsername] = useState(''); 
  const [stockData, setStockData] = useState([]); 
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuteurOpen, setIsAuteurOpen] = useState(false);
  const [isDiffuseurOpen, setIsDiffuseurOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const userRoles = ["AUTEUR", "DIFFUSEUR"]; 

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }
    
        // const response = await fetch('http://localhost:8000/api/getStocks', {
          const response = await fetch(`${BASE_URL}/getStocks`, {

          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          console.log('Données retournées par l\'API:', data); // Vérifiez ce qui est retourné
          setStockData(data); // Stockez les données dans l'état
        } else {
          console.error('Failed to fetch stock data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };
    

    fetchStocks();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/login');
  };

  useEffect(() => {
    const path = location.pathname;

    // Set dropdown open state and highlight selected item
    if (["/livres-auteurs", "/recherche-pro", "/devis" , "/ventes","stocks",].includes(path)) {
      setIsAuteurOpen(true);
      setSelectedItem(path);
    } else if (["/points", "/recherche-auteurs", "/mes-ventes-factures"].includes(path)) {
      setIsDiffuseurOpen(true);
      setSelectedItem(path);
    }
  }, [location.pathname]);

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
         {/* Sidebar */}
         <div className="bg-white shadow-md rounded-lg p-4">
      <ul className="space-y-4">
        {/* Fonctionnalités Globales */}
        <li className="group">
          <a
            href="/compte-auteur-diffuseur"
            className={`font-bold text-gray-600 flex items-center px-2 py-2 rounded-md ${
              location.pathname === "/compte-auteur-diffuseur" ? "border border-green-500" : "hover:border hover:border-green-500"
            }`}
          >
            <span
              className={`hidden ${
                location.pathname === "/compte-auteur-diffuseur" ? "inline-block" : "group-hover:inline-block"
              } text-green-500 mr-2`}
            >
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Tableau de bord
          </a>
        </li>
        <li className="group">
          <a
            href="/informations"
            className={`font-bold text-gray-600 flex items-center px-2 py-2 rounded-md ${
              location.pathname === "/informations" ? "border border-green-500" : "hover:border hover:border-green-500"
            }`}
          >
            <span
              className={`hidden ${
                location.pathname === "/informations" ? "inline-block" : "group-hover:inline-block"
              } text-green-500 mr-2`}
            >
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
            Mes Informations
          </a>
        </li>
        <li className="group">
          <a
            href="/payment"
            className={`font-bold text-gray-600 flex items-center px-2 py-2 rounded-md ${
              location.pathname === "/payment" ? "border border-green-500" : "hover:border hover:border-green-500"
            }`}
          >
            <span
              className={`hidden ${
                location.pathname === "/payment" ? "inline-block" : "group-hover:inline-block"
              } text-green-500 mr-2`}
            >
              <IoMdArrowDropright className="text-green-500 h-6 w-6" />
            </span>
          Facture d'abonnement
          </a>
        </li>

        {/* Dropdown pour les fonctionnalités Auteur */}
        {userRoles.includes("AUTEUR") && (
          <li className="group">
            <div
              className="font-bold text-gray-600 flex items-center cursor-pointer"
              onClick={() => setIsAuteurOpen(!isAuteurOpen)}
            >
              <IoMdArrowDropdown
                className={`h-6 w-6 transition-transform duration-200 ${
                  isAuteurOpen ? "rotate-180" : ""
                }`}
              />
              <span className="ml-2">Fonctionnalités Auteur</span>
            </div>
            {isAuteurOpen && (
              <ul className="pl-6 mt-2 space-y-2">
                {[
                  { href: "/livres-auteurs", label: "Mes livres" },
                  { href: "/recherche-pro", label: "Trouver des professionnels" },
                  { href: "/devis", label: "Devis Impression/Mes demandes Devis" },
                  { href: "/ventes", label: "Mes Ventes" },
                  { href: "/stocks", label: "Gestion de Stock" },


                ].map((item) => (
                  <li key={item.href} className="group">
                    <a
                      href={item.href}
                      className={`font-bold text-gray-600 flex items-center px-2 py-2 rounded-md ${
                        selectedItem === item.href ? "border border-green-500" : "hover:border hover:border-green-500"
                      }`}
                      onClick={() => setSelectedItem(item.href)}
                    >
                      <span
                        className={`hidden ${
                          selectedItem === item.href ? "inline-block" : "group-hover:inline-block"
                        } text-green-500 mr-2`}
                      >
                        <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                      </span>
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        )}

        {/* Dropdown pour les fonctionnalités Diffuseur */}
        {userRoles.includes("DIFFUSEUR") && (
          <li className="group">
            <div
              className="font-bold text-gray-600 flex items-center cursor-pointer"
              onClick={() => setIsDiffuseurOpen(!isDiffuseurOpen)}
            >
              <IoMdArrowDropdown
                className={`h-6 w-6 transition-transform duration-200 ${
                  isDiffuseurOpen ? "rotate-180" : ""
                }`}
              />
              <span className="ml-2">Fonctionnalités Diffuseur</span>
            </div>
            {isDiffuseurOpen && (
              <ul className="pl-6 mt-2 space-y-2">
                {[
                  { href: "/points", label: "Gestion de points de vente" },
                  { href: "/recherche-auteurs", label: "Trouver des auteurs" },
                  { href: "/mes-ventes-factures", label: "Mes Ventes/Factures" },
                ].map((item) => (
                  <li key={item.href} className="group">
                    <a
                      href={item.href}
                      className={`font-bold text-gray-600 flex items-center px-2 py-2 rounded-md ${
                        selectedItem === item.href ? "border border-green-500" : "hover:border hover:border-green-500"
                      }`}
                      onClick={() => setSelectedItem(item.href)}
                    >
                      <span
                        className={`hidden ${
                          selectedItem === item.href ? "inline-block" : "group-hover:inline-block"
                        } text-green-500 mr-2`}
                      >
                        <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                      </span>
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        )}
      </ul>
    </div>
          {/* Main Section */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              {/* Welcome Message */}
              {/* <p className="text-lg mb-4">
                Bonjour <strong>{username || 'Utilisateur'}</strong> (vous n'êtes pas{' '}
                <strong>{username || 'Utilisateur'}</strong>?{' '}
                <span onClick={handleLogout} className="text-blue-600 cursor-pointer">
                  Déconnexion
                </span>)
              </p> */}

              {/* Main Section */}
              <h3 className="text-md font-bold mb-4">
                Stocks des Livres Envoyés
              </h3>

              {/* Stock Table */}
              <div className="overflow-x-auto shadow-lg sm:rounded-lg">
              <table className="min-w-full text-sm text-left text-gray-600">
  <thead className="bg-gray-200 text-gray-800">
    <tr>
      <th scope="col" className="px-6 py-3 text-center">Titre du Livre</th>
      <th scope="col" className="px-6 py-3 text-center">Stock Imprimé</th>
      <th scope="col" className="px-6 py-3 text-center">Stock Editions7</th>
      <th scope="col" className="px-6 py-3 text-center">Stock Envoyé</th>
      <th scope="col" className="px-6 py-3 text-center">Stock Auteur</th>
    </tr>
  </thead>
  <tbody>
  {stockData.length > 0 ? (
    stockData.map((stock, index) => (
      <tr key={index} className="border-b hover:bg-gray-100">
        <td className="px-6 py-4 text-center">{stock.livre}</td>
        <td className="px-6 py-4 text-center">{stock.stock_imprime}</td>
        <td className="px-6 py-4 text-center">{stock.stock_editions7}</td>
        <td className="px-6 py-4 text-center">{stock.stock_envoye}</td>
        <td className="px-6 py-4 text-center">{stock.stock_auteur}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="5" className="text-center py-4 text-gray-500">
        {stockData.length === 0 ? 'Chargement...' : 'Aucun stock disponible'}
      </td>
    </tr>
  )}
</tbody>

</table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stocks;
