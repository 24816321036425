// import React, { useState, useEffect } from 'react';
// import Header1 from './diffuseurs/Header1';
// import axios from 'axios';
// import { IoMdArrowDropright } from 'react-icons/io';

// const LivreVentes = () => {
//     const [livres, setLivres] = useState([]);
//     const [selectedBook, setSelectedBook] = useState(null);
//     const [showModal, setShowModal] = useState(false);
//     const [showSuccessModal, setShowSuccessModal] = useState(false); // Nouvel état pour le modal de succès
//     const [formData, setFormData] = useState({
//         titre: '',
//         quantite: '',
//         informations: '',
//         emailDiffuseur: '', // Ajout du champ email du diffuseur
//     });

//     const fetchLivres = async () => {
//         try {
//             const response = await axios.get('http://localhost:8000/api/livres-Auteur', {
//                 // const response = await axios.get('https://editions7.com/admin/public/api/livres-Auteur', {

//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('token')}`,
//                 },
//             });
//             setLivres(response.data);
//         } catch (error) {
//             console.error('Erreur lors de la récupération des livres :', error);
//         }
//     };

//     useEffect(() => {
//         fetchLivres();
//     }, []);

//     const handleSendClick = (book) => {
//         setSelectedBook(book);
//         setFormData({
//             titre: book.title,
//             quantite: '',
//             informations: '',
//             emailDiffuseur: '',
//         });
//         setShowModal(true);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             await axios.post(
//                 'http://localhost:8000/api/envoyer-livre',
//                 // 'https://editions7.com/admin/public/api/envoyer-livre',

//                 {
//                     titre: formData.titre,
//                     quantite: formData.quantite,
//                     informations: formData.informations,
//                     emailDiffuseur: formData.emailDiffuseur, // Inclure l'email dans la requête
//                 },
//                 {
//                     headers: {
//                         Authorization: `Bearer ${localStorage.getItem('token')}`,
//                     },
//                 }
//             );
//             setShowModal(false); // Fermer le modal de formulaire
//             setShowSuccessModal(true); // Afficher le modal de succès
//         } catch (error) {
//             console.error('Erreur lors de l\'envoi du livre :', error);
//             alert(
//                 error.response?.data?.message ||
//                 'Une erreur est survenue lors de l\'envoi du livre.'
//             );
//         }
//     };

//     return (
//         <div className="bg-white min-h-screen">
//             <Header1 />
//             <div className="container mx-auto px-4 py-8">
//                 <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
//                                {/* Sidebar */}
//          <div className="bg-white shadow-md rounded-lg p-4">
// <ul className="space-y-4">
//   <li className="group">
//     <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Tableau de bord
//     </a>
//   </li>
//   <li className="group">
//     <a href="/mes-informations" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
//     <span className="inline-block text-green-500 mr-2">
//       <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
//     </span>
// Mes Informations    </a>
//   </li>
//   <li className="group">
//     <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Trouver des professionnels
//     </a>
//   </li>
//   <li className="group">
//     <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Mes livre
//     </a>
//   </li>
 
 
//   <li className="group">
//     <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Mes publications
//     </a>
//   </li>
//   {/* <li className="group">
//     <a href="/devis-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
//       </span>
//       Devis Impression / demande
//     </a>
//   </li> */}
//   <li className="group">
//     <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Conseil communication
//     </a>
//   </li>
//   <li className="group">
//     <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Vente de livres
//     </a>
//   </li>
  
// </ul>


// </div>

//                     <div className="md:col-span-3">
//                         <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
//                             <h1 className="text-2xl font-bold mb-6">Mes Ventes</h1>
//                             <table className="table-auto border-collapse border border-gray-300 w-full">
//                                 <thead>
//                                     <tr className="bg-gray-200">
//                                         <th className="border border-gray-300 p-2">Nom du Livre</th>
//                                         <th className="border border-gray-300 p-2">Description</th>
//                                         <th className="border border-gray-300 p-2">Prix</th>
//                                         <th className="border border-gray-300 p-2">Actions</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {livres.map((livre) => (
//                                         <tr key={livre.id}>
//                                             <td className="border border-gray-300 p-2">{livre.title}</td>
//                                             <td className="border border-gray-300 p-2">{livre.description}</td>
//                                             <td className="border border-gray-300 p-2">{livre.price} €</td>
//                                             <td className="border border-gray-300 p-2">
//                                                 <button
//                                                     className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
//                                                     onClick={() => handleSendClick(livre)}
//                                                 >
//                                                     Envoyer
//                                                 </button>
//                                             </td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Modal de formulaire */}
//                 {showModal && (
//                     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//                         <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
//                             <h2 className="text-xl font-bold mb-4">Envoyer le Livre</h2>
//                             <form onSubmit={handleSubmit}>
//                                 <div className="mb-4">
//                                     <label className="block font-bold mb-2">Titre :</label>
//                                     <input
//                                         type="text"
//                                         className="border border-gray-300 p-2 w-full"
//                                         value={formData.titre}
//                                         readOnly
//                                     />
//                                 </div>
//                                 <div className="mb-4">
//                                     <label className="block font-bold mb-2">Quantité :</label>
//                                     <input
//                                         type="number"
//                                         className="border border-gray-300 p-2 w-full"
//                                         value={formData.quantite}
//                                         onChange={(e) =>
//                                             setFormData({ ...formData, quantite: e.target.value })
//                                         }
//                                         required
//                                     />
//                                 </div>
//                                 <div className="mb-4">
//                                     <label className="block font-bold mb-2">Informations :</label>
//                                     <textarea
//                                         className="border border-gray-300 p-2 w-full"
//                                         value={formData.informations}
//                                         onChange={(e) =>
//                                             setFormData({ ...formData, informations: e.target.value })
//                                         }
//                                         required
//                                     />
//                                 </div>
//                                 <div className="mb-4">
//                                     <label className="block font-bold mb-2">Email du Diffuseur :</label>
//                                     <input
//                                         type="email"
//                                         className="border border-gray-300 p-2 w-full"
//                                         value={formData.emailDiffuseur}
//                                         onChange={(e) =>
//                                             setFormData({ ...formData, emailDiffuseur: e.target.value })
//                                         }
//                                         required
//                                     />
//                                 </div>
//                                 <div className="flex justify-end space-x-4">
//                                     <button
//                                         type="button"
//                                         className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
//                                         onClick={() => setShowModal(false)}
//                                     >
//                                         Annuler
//                                     </button>
//                                     <button
//                                         type="submit"
//                                         className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
//                                     >
//                                         Envoyer
//                                     </button>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 )}

//                 {/* Modal de succès */}
//                 {showSuccessModal && (
//                     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//                         <div className="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
//                             <h2 className="text-xl font-bold mb-4 text-green-500">
//                                 Succès
//                             </h2>
//                             <p>Le livre a été envoyé avec succès au diffuseur !</p>
//                             <button
//                                 className="bg-green-500 text-white px-4 py-2 rounded-lg mt-4 hover:bg-green-600"
//                                 onClick={() => setShowSuccessModal(false)}
//                             >
//                                 OK
//                             </button>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default LivreVentes;


// // import React, { useState, useEffect } from 'react';
// // import Header1 from './diffuseurs/Header1';
// // import axios from 'axios';
// // import { IoMdArrowDropright } from 'react-icons/io';

// // const LivreVentes = () => {
// //     const [livres, setLivres] = useState([]);
// //     const [diffuseurs, setDiffuseurs] = useState([]);
// //     const [author, setAuthor] = useState({});
// //     const [selectedBook, setSelectedBook] = useState(null);
// //     const [showModal, setShowModal] = useState(false);
// //     const [showSuccessModal, setShowSuccessModal] = useState(false);
// //     const [formData, setFormData] = useState({
// //         titre: '',
// //         quantite: '',
// //         informations: '',
// //         emailDiffuseur: '',
// //     });

// //     // Fetch livres for the author
// //     const fetchLivres = async () => {
// //         try {
// //             const response = await axios.get('http://localhost:8000/api/livres-Auteur', {
// //                 headers: {
// //                     Authorization: `Bearer ${localStorage.getItem('token')}`,
// //                 },
// //             });
// //             setLivres(response.data);
// //         } catch (error) {
// //             console.error('Erreur lors de la récupération des livres :', error);
// //         }
// //     };

// //     // Fetch diffuseurs (list of users with role "DIFFUSEUR")
// //     const fetchDiffuseurs = async () => {
// //         try {
// //             const response = await axios.get('http://localhost:8000/api/diffuseurs', {
// //                 headers: {
// //                     Authorization: `Bearer ${localStorage.getItem('token')}`,
// //                 },
// //             });
// //             setDiffuseurs(response.data);
// //         } catch (error) {
// //             console.error('Erreur lors de la récupération des diffuseurs :', error);
// //         }
// //     };

// //     // Fetch connected author's details
// //     const fetchAuthorDetails = async () => {
// //         try {
// //             const response = await axios.get('http://localhost:8000/api/auteur-details', {
// //                 headers: {
// //                     Authorization: `Bearer ${localStorage.getItem('token')}`,
// //                 },
// //             });
// //             setAuthor(response.data);
// //         } catch (error) {
// //             console.error('Erreur lors de la récupération des détails de l\'auteur :', error);
// //         }
// //     };

// //     useEffect(() => {
// //         fetchLivres();
// //         fetchDiffuseurs();
// //         fetchAuthorDetails();
// //     }, []);

// //     const handleSendClick = (book) => {
// //         setSelectedBook(book);
// //         setFormData({
// //             titre: book.title,
// //             quantite: '',
// //             informations: '',
// //             emailDiffuseur: '',
// //         });
// //         setShowModal(true);
// //     };

// //     // const handleSubmit = async (e) => {
// //     //     e.preventDefault();
// //     //     try {
// //     //         await axios.post(
// //     //             'http://localhost:8000/api/envoyer-livre',
// //     //             {
// //     //                 titre: formData.titre,
// //     //                 quantite: formData.quantite,
// //     //                 informations: formData.informations,
// //     //                 emailDiffuseur: formData.emailDiffuseur,
// //     //                 auteur_email: author.email,
// //     //                 auteur_username: author.name,
// //     //             },
// //     //             {
// //     //                 headers: {
// //     //                     Authorization: `Bearer ${localStorage.getItem('token')}`,
// //     //                 },
// //     //             }
// //     //         );
// //     //         setShowModal(false);
// //     //         setShowSuccessModal(true);
// //     //     } catch (error) {
// //     //         console.error('Erreur lors de l\'envoi du livre :', error);
// //     //         alert(
// //     //             error.response?.data?.message ||
// //     //             'Une erreur est survenue lors de l\'envoi du livre.'
// //     //         );
// //     //     }
// //     // };

// //     const handleSubmit = async (e) => {
// //         e.preventDefault();
// //         try {
// //             await axios.post(
// //                 'http://localhost:8000/api/envoyer-livre',
// //                 formData,
// //                 {
// //                     headers: {
// //                         Authorization: `Bearer ${localStorage.getItem('token')}`,
// //                     },
// //                 }
// //             );
// //             alert('Livre envoyé avec succès');
// //             setShowModal(false);
// //         } catch (error) {
// //             console.error('Erreur lors de l\'envoi du livre :', error);
// //             alert('Erreur lors de l\'envoi du livre.');
// //         }
// //     };
// //     return (
// //         <div className="bg-white min-h-screen">
// //             <Header1 />
// //             <div className="container mx-auto px-4 py-8">
// //                 <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
// //                     <div className="md:col-span-3">
// //                         <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
// //                             <h1 className="text-2xl font-bold mb-6">Mes Ventes</h1>
// //                             <table className="table-auto border-collapse border border-gray-300 w-full">
// //                                 <thead>
// //                                     <tr className="bg-gray-200">
// //                                         <th className="border border-gray-300 p-2">Nom du Livre</th>
// //                                         <th className="border border-gray-300 p-2">Description</th>
// //                                         <th className="border border-gray-300 p-2">Prix</th>
// //                                         <th className="border border-gray-300 p-2">Actions</th>
// //                                     </tr>
// //                                 </thead>
// //                                 <tbody>
// //                                     {livres.map((livre) => (
// //                                         <tr key={livre.id}>
// //                                             <td className="border border-gray-300 p-2">{livre.title}</td>
// //                                             <td className="border border-gray-300 p-2">{livre.description}</td>
// //                                             <td className="border border-gray-300 p-2">{livre.price} €</td>
// //                                             <td className="border border-gray-300 p-2">
// //                                                 <button
// //                                                     className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
// //                                                     onClick={() => handleSendClick(livre)}
// //                                                 >
// //                                                     Envoyer
// //                                                 </button>
// //                                             </td>
// //                                         </tr>
// //                                     ))}
// //                                 </tbody>
// //                             </table>
// //                         </div>
// //                     </div>
// //                 </div>

// //                 {/* Modal de formulaire */}
// //                 {showModal && (
// //                     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
// //                         <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
// //                             <h2 className="text-xl font-bold mb-4">Envoyer le Livre</h2>
// //                             <form onSubmit={handleSubmit}>
// //                                 <div className="mb-4">
// //                                     <label className="block font-bold mb-2">Titre :</label>
// //                                     <input
// //                                         type="text"
// //                                         className="border border-gray-300 p-2 w-full"
// //                                         value={formData.titre}
// //                                         readOnly
// //                                     />
// //                                 </div>
// //                                 <div className="mb-4">
// //                                     <label className="block font-bold mb-2">Quantité :</label>
// //                                     <input
// //                                         type="number"
// //                                         className="border border-gray-300 p-2 w-full"
// //                                         value={formData.quantite}
// //                                         onChange={(e) =>
// //                                             setFormData({ ...formData, quantite: e.target.value })
// //                                         }
// //                                         required
// //                                     />
// //                                 </div>
// //                                 <div className="mb-4">
// //                                     <label className="block font-bold mb-2">Informations :</label>
// //                                     <textarea
// //                                         className="border border-gray-300 p-2 w-full"
// //                                         value={formData.informations}
// //                                         onChange={(e) =>
// //                                             setFormData({ ...formData, informations: e.target.value })
// //                                         }
// //                                         required
// //                                     />
// //                                 </div>
// //                                 <div className="mb-4">
// //                                     <label className="block font-bold mb-2">Email du Diffuseur :</label>
// //                                     <select
// //                                         className="border border-gray-300 p-2 w-full"
// //                                         value={formData.emailDiffuseur}
// //                                         onChange={(e) =>
// //                                             setFormData({ ...formData, emailDiffuseur: e.target.value })
// //                                         }
// //                                         required
// //                                     >
// //                                         <option value="">-- Sélectionner un diffuseur --</option>
// //                                         {diffuseurs.map((diffuseur) => (
// //                                             <option key={diffuseur.id} value={diffuseur.email}>
// //                                                 {diffuseur.name} ({diffuseur.email})
// //                                             </option>
// //                                         ))}
// //                                     </select>
// //                                 </div>
// //                                 <div className="flex justify-end space-x-4">
// //                                     <button
// //                                         type="button"
// //                                         className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
// //                                         onClick={() => setShowModal(false)}
// //                                     >
// //                                         Annuler
// //                                     </button>
// //                                     <button
// //                                         type="submit"
// //                                         className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
// //                                     >
// //                                         Envoyer
// //                                     </button>
// //                                 </div>
// //                             </form>
// //                         </div>
// //                     </div>
// //                 )}

// //                 {/* Modal de succès */}
// //                 {showSuccessModal && (
// //                     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
// //                         <div className="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
// //                             <h2 className="text-xl font-bold mb-4 text-green-500">
// //                                 Succès
// //                             </h2>
// //                             <p>Le livre a été envoyé avec succès au diffuseur !</p>
// //                             <button
// //                                 className="bg-green-500 text-white px-4 py-2 rounded-lg mt-4 hover:bg-green-600"
// //                                 onClick={() => setShowSuccessModal(false)}
// //                             >
// //                                 OK
// //                             </button>
// //                         </div>
// //                     </div>
// //                 )}
// //             </div>
// //         </div>
// //     );
// // };

// // export default LivreVentes;

import React, { useState, useEffect } from 'react';
import Header1 from './diffuseurs/Header1';
import axios from 'axios';
import { IoMdArrowDropright } from 'react-icons/io';
import BASE_URL from '../key';


const LivreVentes = () => {
    const [livres, setLivres] = useState([]);
    const [selectedBook, setSelectedBook] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false); // Affiche le modal de succès
    const [formData, setFormData] = useState({
        titre: '',
        quantite: '',
        informations: '',
        emailDiffuseur: '',
    });

    // Récupérer la liste des livres
    const fetchLivres = async () => {
        try {
            // const response = await axios.get('http://localhost:8000/api/ventes', {
                const response = await axios.get(`${BASE_URL}/ventes`, {

                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setLivres(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des livres :', error);
        }
    };
    

    useEffect(() => {
        fetchLivres();
    }, []);

    // Gestion de l'action "Envoyer"
    const handleSendClick = (book) => {
        setSelectedBook(book);
        setFormData({
            titre: book.title,
            quantite: '',
            informations: '',
            emailDiffuseur: '',
        });
        setShowModal(true);
    };

    // Gestion de l'envoi du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(
                // 'http://localhost:8000/api/envoyer-livre',
              `${BASE_URL}/envoyer-livre`,

                {
                    titre: formData.titre,
                    quantite: formData.quantite,
                    informations: formData.informations,
                    emailDiffuseur: formData.emailDiffuseur,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            // Mettre à jour le statut du livre localement
            const updatedLivres = livres.map((livre) =>
                livre.id === selectedBook.id ? { ...livre, statut: 'À envoyer' } : livre
            );
            setLivres(updatedLivres);

            setShowModal(false); 
            setShowSuccessModal(true); 
        } catch (error) {
            console.error('Erreur lors de l\'envoi du livre :', error);
            alert('Une erreur est survenue lors de l\'envoi du livre.');
        }
    };

    useEffect(() => {
        console.log('Livres récupérés :', livres);
    }, [livres]);
    

    return (
         <div className="bg-white min-h-screen">
            <Header1 />
            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                               {/* Sidebar */}
         <div className="bg-white shadow-md rounded-lg p-4">
<ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
    <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
Mes Informations    </a>
  </li>
  <li className="group">
    <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes livres
    </a>
  </li>
 
 
 
  <li className="group">
    <a href="/devis-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Devis Impression / demande
    </a>
  </li>


  {/* <li className="group">
    <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Conseil communication
    </a>
  </li> */}
  <li className="group">
    <a href="/book-sales" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
      Vente de livres
    </a>
  </li>
  <li className="group">
    <a href="/stock" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
     Gestion de Stock
    </a>
  </li>
  <li className="group">
                <a
                  href="/payments"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Mes paiements
                </a>
              </li>
</ul>


</div>

                    <div className="md:col-span-3">
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
                            <h1 className="text-2xl font-bold mb-6">Mes Ventes</h1>
                            <table className="table-auto border-collapse border border-gray-300 w-full">
    <thead>
        <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Titre du Livre</th>
            <th className="border border-gray-300 p-2">Description</th>
            <th className="border border-gray-300 p-2">De (AUTEUR)</th>
            <th className="border border-gray-300 p-2">À (DIFFUSEUR)</th>
            <th className="border border-gray-300 p-2">Status</th>
        </tr>
    </thead>
    <tbody>
        {livres.map((livre) => (
            <tr key={livre.id}>
                <td className="border border-gray-300 p-2">{livre.title}</td>
                <td className="border border-gray-300 p-2">{livre.description}</td>
                <td className="border border-gray-300 p-2">
                    {livre.auteur_name} {livre.auteur_prenom}
                </td>
                <td className="border border-gray-300 p-2">
                    {livre.diffuseur_name} {livre.diffuseur_prenom}
                </td>
                <td className="border border-gray-300 p-2">{livre.status}</td>

            </tr>
        ))}
    </tbody>
</table>

                </div>
            </div>
            </div>
            </div>
            

            {/* Modal d'envoi */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <h2 className="text-xl font-bold mb-4">Envoyer le Livre</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block font-bold mb-2">Titre :</label>
                                <input
                                    type="text"
                                    className="border border-gray-300 p-2 w-full"
                                    value={formData.titre}
                                    readOnly
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block font-bold mb-2">Quantité :</label>
                                <input
                                    type="number"
                                    className="border border-gray-300 p-2 w-full"
                                    value={formData.quantite}
                                    onChange={(e) => setFormData({ ...formData, quantite: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block font-bold mb-2">Informations :</label>
                                <textarea
                                    className="border border-gray-300 p-2 w-full"
                                    value={formData.informations}
                                    onChange={(e) => setFormData({ ...formData, informations: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block font-bold mb-2">Email du Diffuseur :</label>
                                <input
                                    type="email"
                                    className="border border-gray-300 p-2 w-full"
                                    value={formData.emailDiffuseur}
                                    onChange={(e) => setFormData({ ...formData, emailDiffuseur: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                                    onClick={() => setShowModal(false)}
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                >
                                    Envoyer
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Modal de succès */}
            {showSuccessModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
                        <h2 className="text-xl font-bold mb-4 text-green-500">Succès</h2>
                        <p>Le livre a été envoyé avec succès au diffuseur !</p>
                        <button
                            className="bg-green-500 text-white px-4 py-2 rounded-lg mt-4 hover:bg-green-600"
                            onClick={() => setShowSuccessModal(false)}
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LivreVentes;
