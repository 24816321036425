import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header1 from "./Header1";
import { IoMdArrowDropright } from "react-icons/io";
import BASE_URL from "../../key";

const LivreDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [livre, setLivre] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false); 
  const [newStatus, setNewStatus] = useState(""); 
  const [quantiteRecue, setQuantiteRecue] = useState(0);


  const fetchLivreDetails = async () => {
    try {
      setLoading(true);
      // const response = await fetch(`http://localhost:8000/api/livre/${id}`, {
        const response = await fetch(`${BASE_URL}/livre/${id}`, {

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("Livre introuvable.");
        }
        throw new Error(`Erreur serveur : ${response.status}`);
      }
  
      const data = await response.json();
      setLivre(data);
  
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  


 
  
  useEffect(() => {
    fetchLivreDetails();
  }, [id]);

  const handleSaveStatus = async () => {
    if (!newStatus.trim()) {
      alert("Le statut ne peut pas être vide.");
      return;
    }

    try {
      const response = await fetch(
        // `http://localhost:8000/api/diffuseur/livres/${id}`,
        `${BASE_URL}/diffuseur/livres/${id}`,

        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ status: newStatus, quantite_recue: quantiteRecue }),
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour du statut.");
      }

      await fetchLivreDetails();
      setIsEditing(false); 
    } catch (err) {
      console.error(err.message);
    }
  };

  if (loading) {
    return <div className="text-gray-500">Chargement...</div>;
  }

  if (error) {
    return (
      <div className="text-red-500">
        <p>Erreur : {error}</p>
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => navigate("/")}
        >
          Retour à l'accueil
        </button>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header1 />
      <div className="container mx-auto py-10 px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Sidebar */}
          <div className="bg-white shadow-md rounded-lg p-4">
                        <ul className="space-y-4">
                            <li className="group">
                                <a href="/compte-diffuseur"  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Tableau de bord
                                </a>
                            </li>
                        
                            <li className="group">
                                <a href="/rechercher-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Trouver des auteurs
                                </a>
                            </li>
                            <li className="group">
                                <a href="/information-diffuseur"className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes Informations
                                </a>
                            </li>
                            <li className="group">
                                <a href="/points-ventes"className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Gestion de points de vente 
                                </a>
                            </li>
                            {/* <li className="group">
                                <a href="/factures" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
                                    </span>
                                    Mes Ventes/factures                                </a>
                            </li> */}
    
                            <li className="group">
                                <a href="/mes-ventes" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
                                    </span>
                                    Mes Ventes/Factures   
                                </a>
                            </li>
                            <li className="group">
                <a
                  href="/factures"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Facture d'abonnement
                </a>
              </li>
                          {/* <li className="group">
                                <a href="/commandes" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
                                    </span>
                                    Commandes
                                </a>
                            </li> */}
                         
                        </ul>
                    </div>
          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
              <h1 className="text-3xl font-bold text-gray-800 mb-4">{livre.title}</h1>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Première colonne */}
                <div>
                  <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Titre de Livre :</span>{" "}
                    {livre.title || "Aucun résumé disponible."}
                  </p>
                  {/* <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Quantité :</span>{" "}
                    {livre.quantite_recue || "Aucun uanntite disponible."}
                  </p> */}
                  <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Résumé :</span>{" "}
                    {livre.resume || "Aucun résumé disponible."}
                  </p>
                  <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Pitch :</span>{" "}
                    {livre.pitch || "Aucun pitch disponible."}
                  </p>
                  <p className="text-gray-600 mb-6">
                    <span className="font-semibold text-gray-800">Auteur :</span>{" "}
                    {livre.auteur?.username || "Nom d'utilisateur indisponible"}{" "}
                    {livre.auteur?.prenom || ""}
                  </p>
                </div>
                

                {/* Deuxième colonne */}
                <div>
                <div className="mb-6">
                    <span className="font-semibold text-gray-800">Prix :</span>{" "}
                    <span className="ml-2 text-gray-500">{livre.price} €</span>
                  </div>

                  <div className="mb-6">
  <span className="font-semibold text-gray-800">Quantité Envoyée :</span>{" "}
  <span className="ml-2 text-gray-500">{livre.quantite_envoyee || 0}</span>
</div>

{/* <div className="mb-6">
  <span className="font-semibold text-gray-800">Quantité Reçue :</span>{" "}
  <span className="ml-2 text-gray-500">{quantiteRecue || 0}</span>
</div> */}


                <div className="mb-6">
  <span className="font-semibold text-gray-800">Statut :</span>{" "}
  {isEditing === "status" ? (
    <div className="flex items-center space-x-2">
      <select
        className="border border-gray-300 rounded px-2 py-1 w-1/2"
        value={newStatus}
        onChange={(e) => setNewStatus(e.target.value)}
      >
        <option value="Reçu">Reçu</option>
        <option value="Rejeté">Rejeté</option>
      </select>
      <button
        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
        onClick={() => {
          handleSaveStatus("status");
          setIsEditing(null);
        }}
      >
        Modifier
      </button>
      <button
        className="bg-gray-500 text-white px-3 py-1 rounded hover:bg-gray-600"
        onClick={() => setIsEditing(null)}
      >
        Annuler
      </button>
    </div>
  ) : (
    <span
      className="ml-2 text-gray-500 cursor-pointer underline"
      onClick={() => setIsEditing("status")}
    >
      {livre.status || "Cliquer pour modifier"}
    </span>
  )}
</div>


<div className="mb-6">
  <span className="font-semibold text-gray-800"> Quantité Reçue : :</span>{" "}
  {isEditing === "quantite_recue" ? (
    <div className="flex items-center space-x-2">
      <input
        type="number"
        className="border border-gray-300 rounded px-2 py-1 w-1/2"
        value={quantiteRecue}
        onChange={(e) => setQuantiteRecue(e.target.value)}
      />
      <button
        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
        onClick={() => {
          handleSaveStatus("quantite_recue");
          setIsEditing(null);
        }}
      >
        Modifier
      </button>
      <button
        className="bg-gray-500 text-white px-3 py-1 rounded hover:bg-gray-600"
        onClick={() => setIsEditing(null)}
      >
        Annuler
      </button>
    </div>
  ) : (
    <span
      className="ml-2 text-gray-500 cursor-pointer underline"
      onClick={() => setIsEditing("quantite_recue")}
    >
      {quantiteRecue || "Cliquer pour modifier"}
    </span>
  )}
</div>

                 
                  {livre.cover_first ? (
                    <img
                      // src={`http://localhost:8000/storage/${livre.cover_first.replace(
                      //   "http://localhost:8000/storage/",
                        src={`https://editions7.com/admin/public/storage/${livre.cover_first.replace(
                          "https://editions7.com/admin/public/storage/",
                    // src={`https://editions7.com/admin/public/storage/${livre.cover_first.replace(
                    //     "https://editions7.com/admin/public/storage/",
                        ""
                      )}`}
                      alt="Première couverture"
                      className="rounded-lg border border-gray-300 shadow-md object-cover w-full max-w-xs"
                    />
                  ) : (
                    <div className="bg-gray-200 rounded-lg flex items-center justify-center h-64">
                      <span className="text-gray-500">Aucune couverture disponible</span>
                    </div>
                  )}
                </div>

              </div>

              {/* Bouton retour */}
              <button
                className="mt-6 bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600"
                onClick={() => navigate(-1)} 
              >
                Retour
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivreDetails;
