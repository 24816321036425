import React, { useEffect, useState } from "react";
import BASE_URL from "../key"; // Assurez-vous que BASE_URL pointe vers l'API correcte
import { IoMdArrowDropright } from "react-icons/io";

const ConditionsGenerales = () => {
  const [content, setContent] = useState(null); // Stocker les données récupérées
  const [loading, setLoading] = useState(true); // Gérer l'état de chargement
  const [error, setError] = useState(null); // Gérer les erreurs

  useEffect(() => {
    // Récupérer les données depuis l'API
    fetch(`${BASE_URL}/conditions-generales`) // Remplacez par l'URL correcte de votre API
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erreur lors du chargement des données.");
        }
        return response.json();
      })
      .then((data) => {
        setContent(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  // Affichage si le contenu est en cours de chargement
  if (loading) {
    return <p className="text-center text-gray-500">Chargement...</p>;
  }

  // Affichage en cas d'erreur
  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  // Affichage du contenu récupéré
  return (
    <div className="max-w-7xl mx-auto p-6 mb-96">
      {/* Titre avec icône aligné à gauche */}
      <div className="flex items-center mb-6">
        <IoMdArrowDropright className="text-red-500 text-5xl mr-3" />
        <h1 className="text-4xl font-bold text-gray-800">Conditions Générales</h1>
      </div>

      {/* Contenu des conditions générales */}
      <div className="text-gray-600 text-lg leading-relaxed text-left">
        <p dangerouslySetInnerHTML={{ __html: content.content }}></p>
      </div>
    </div>
  );
};

export default ConditionsGenerales;
