// import React, { useState } from 'react';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import L from 'leaflet';
// import { IoMdArrowDropright } from 'react-icons/io';

// import Header1 from './diffuseurs/Header1';

// // Custom icons for different categories
// const defaultUserIcon = new L.Icon({
//   iconUrl: 'https://cdn-icons-png.flaticon.com/512/64/64572.png', // Black dot icon for all users
//   iconSize: [15, 15],
// });

// const diffuseurIcon = new L.Icon({
//   iconUrl: 'https://cdn-icons-png.flaticon.com/512/3064/3064231.png',
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
// });

// const graphisteIcon = new L.Icon({
//   iconUrl: 'https://cdn-icons-png.flaticon.com/512/149/149071.png',
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
// });

// const pointSaleIcon = new L.Icon({
//   iconUrl: 'https://cdn-icons-png.flaticon.com/512/2026/2026395.png', // Green for served points of sale
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
// });

// const pointSalePotentialIcon = new L.Icon({
//   iconUrl: 'https://cdn-icons-png.flaticon.com/512/149/149071.png', // Yellow for potential points of sale
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
// });

// // Example data for professionals and points of sale
// const professionals = [
//   { name: "Diffuseur 1", lat: 48.8566, lng: 2.3522, type: "Diffuseur", city: "Paris", region: "Île-de-France", country: "France" },
//   { name: "Graphiste 1", lat: 45.764, lng: 4.8357, type: "Graphiste", city: "Lyon", region: "Auvergne-Rhône-Alpes", country: "France" },
//   { name: "Auteur 1", lat: 43.7102, lng: 7.2620, type: "Auteur", city: "Nice", region: "Provence-Alpes-Côte d'Azur", country: "France" },
// ];

// const pointsOfSale = [
//   { name: "Librairie 1", lat: 48.8586, lng: 2.3472, type: "Librairie", city: "Paris", region: "Île-de-France", country: "France", served: true },
//   { name: "Maison de Presse 1", lat: 45.76, lng: 4.84, type: "Presse", city: "Lyon", region: "Auvergne-Rhône-Alpes", country: "France", served: false },
// ];

// const TrouverDesProfessionnels = () => {
//   const [selectedProfession, setSelectedProfession] = useState('');
//   const [selectedRegion, setSelectedRegion] = useState('');
//   const [selectedCity, setSelectedCity] = useState('');
//   const [selectedCountry, setSelectedCountry] = useState('');
//   const [searchTriggered, setSearchTriggered] = useState(false);
//   const [viewType, setViewType] = useState('all-users'); // Controls which type of view to show

//   const professions = [
//     'Diffuseurs (Mandataire d\'édition)',
//     'Graphistes',
//     'Illustrateurs',
//     'Traducteurs',
//     'Chargé d\'édition',
//     'Community Manager',
//     'Chargé de relation presse',
//     'Auteurs',
//   ];

//   const regions = ['Île-de-France', 'Auvergne-Rhône-Alpes', 'Nouvelle-Aquitaine', 'Provence-Alpes-Côte d\'Azur'];
//   const cities = ['Paris', 'Lyon', 'Marseille'];
//   const countries = ['France', 'Belgique', 'Luxembourg', 'Suisse'];

//   const handleSearch = () => {
//     setSearchTriggered(true);
//   };

//   const filterResults = () => {
//     return professionals.filter(pro =>
//       (!selectedProfession || pro.type === selectedProfession) &&
//       (!selectedRegion || pro.region === selectedRegion) &&
//       (!selectedCity || pro.city === selectedCity) &&
//       (!selectedCountry || pro.country === selectedCountry)
//     );
//   };

//   const filterPointsOfSale = () => {
//     return pointsOfSale.filter(point =>
//       (!selectedRegion || point.region === selectedRegion) &&
//       (!selectedCity || point.city === selectedCity) &&
//       (!selectedCountry || point.country === selectedCountry)
//     );
//   };

//   return (
//     <div className="bg-white min-h-screen">
//       {/* Header */}
//       <Header1 />

//       <div className="container mx-auto px-4 py-8">
//         <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
//              {/* Sidebar */}
//              <div className="bg-white shadow-md rounded-lg p-4">
// <ul className="space-y-4">
//   <li className="group">
//     <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Tableau de bord
//     </a>
//   </li>
//   <li className="group">
//                                 <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//                                     <span className="hidden group-hover:inline-block text-green-500 mr-2">
//                                         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//                                     </span>
//                                     Mes Informations
//                                 </a>
//                             </li>
//                             <li className="group">
//     <a href="/recherchre-diffuseurs"className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
//     <span className="inline-block text-green-500 mr-2">
//       <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
//     </span>
//       Trouver des professionnels
//     </a>
//   </li>
//   <li className="group">
//     <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Mes livre
//     </a>
//   </li>
 

//   <li className="group">
//     <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Mes publications
//     </a>
//   </li>
//   {/* <li className="group">
//     <a href="//devis-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" />
//       </span>
//       Devis Impression / demande
//     </a>
//   </li> */}
//   <li className="group">
//     <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Conseil communication
//     </a>
//   </li>
//   <li className="group">
//     <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Vente de livres
//     </a>
//   </li>
  
// </ul>


// </div>

//           {/* Main Content */}
//           <div className="md:col-span-3">
//             <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
//               <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500">Trouver des Professionnels</h2>

//               {/* Profession Search */}
//               <div className="mb-4">
//                 <label className="block font-bold text-[#737373] mb-2">Rechercher par typologie de métier :</label>
//                 <select
//                   className="border border-gray-300 p-2 w-full rounded-md"
//                   value={selectedProfession}
//                   onChange={(e) => setSelectedProfession(e.target.value)}
//                 >
//                   <option value="">Sélectionner un métier</option>
//                   {professions.map((profession) => (
//                     <option key={profession} value={profession}>
//                       {profession}
//                     </option>
//                   ))}
//                 </select>
//               </div>

//               {/* Geographic Search */}
//               <div className="mb-4">
//                 <label className="block font-bold text-[#737373] mb-2">Rechercher géographiquement :</label>
//                 <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
//                   <select
//                     className="border border-gray-300 p-2 w-full rounded-md"
//                     value={selectedRegion}
//                     onChange={(e) => setSelectedRegion(e.target.value)}
//                   >
//                     <option value="">Sélectionner une région</option>
//                     {regions.map((region) => (
//                       <option key={region} value={region}>
//                         {region}
//                       </option>
//                     ))}
//                   </select>
//                   <select
//                     className="border border-gray-300 p-2 w-full rounded-md"
//                     value={selectedCity}
//                     onChange={(e) => setSelectedCity(e.target.value)}
//                   >
//                     <option value="">Sélectionner une ville</option>
//                     {cities.map((city) => (
//                       <option key={city} value={city}>
//                         {city}
//                       </option>
//                     ))}
//                   </select>
//                   <select
//                     className="border border-gray-300 p-2 w-full rounded-md"
//                     value={selectedCountry}
//                     onChange={(e) => setSelectedCountry(e.target.value)}
//                   >
//                     <option value="">Sélectionner un pays</option>
//                     {countries.map((country) => (
//                       <option key={country} value={country}>
//                         {country}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>

//               {/* Search Button */}
//               <div className="flex justify-end mb-4">
//                 <button
//                   className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
//                   onClick={handleSearch}
//                 >
//                   Rechercher
//                 </button>
//               </div>

//               {/* Map and Results */}
//               {searchTriggered && (
//                 <div className="mt-8">
//                   <h3 className="text-lg font-bold mb-4">Résultats de la recherche :</h3>
//                   <MapContainer
//                     center={[48.8566, 2.3522]} // Initial center of the map (Paris)
//                     zoom={5}
//                     style={{ height: "500px", width: "100%" }}
//                   >
//                     <TileLayer
//                       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                       attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//                     />

//                     {/* Displaying Professional Markers */}
//                     {filterResults().map((pro, index) => (
//                       <Marker
//                         key={index}
//                         position={[pro.lat, pro.lng]}
//                         icon={
//                           selectedProfession ? (pro.type === "Diffuseur" ? diffuseurIcon : graphisteIcon) : defaultUserIcon
//                         }
//                       >
//                         <Popup>
//                           <strong>{pro.name}</strong><br />
//                           {pro.type}<br />
//                           {pro.city}
//                         </Popup>
//                       </Marker>
//                     ))}

//                     {/* Displaying Points of Sale */}
//                     {filterPointsOfSale().map((point, index) => (
//                       <Marker
//                         key={index}
//                         position={[point.lat, point.lng]}
//                         icon={point.served ? pointSaleIcon : pointSalePotentialIcon}
//                       >
//                         <Popup>
//                           <strong>{point.name}</strong><br />
//                           {point.type}<br />
//                           {point.city}
//                         </Popup>
//                       </Marker>
//                     ))}
//                   </MapContainer>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TrouverDesProfessionnels;






import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import Header1 from "./diffuseurs/Header1"; 
import { IoMdArrowDropright } from "react-icons/io"; 
import BASE_URL from '../key';


const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 46.603354, // Center of France
  lng: 1.888334,
};

const departements = [
  { code: "01", name: "Ain" },
  { code: "02", name: "Aisne" },
  { code: "03", name: "Allier" },
  { code: "04", name: "Alpes-de-Haute-Provence" },
  { code: "05", name: "Hautes-Alpes" },
  { code: "06", name: "Alpes-Maritimes" },
  { code: "07", name: "Ardèche" },
  { code: "08", name: "Ardennes" },
  { code: "09", name: "Ariège" },
  { code: "10", name: "Aube" },
  { code: "11", name: "Aude" },
  { code: "12", name: "Aveyron" },
  { code: "13", name: "Bouches-du-Rhône" },
  { code: "14", name: "Calvados" },
  { code: "15", name: "Cantal" },
  { code: "16", name: "Charente" },
  { code: "17", name: "Charente-Maritime" },
  { code: "18", name: "Cher" },
  { code: "19", name: "Corrèze" },
  { code: "2A", name: "Corse-du-Sud" },
  { code: "2B", name: "Haute-Corse" },
  { code: "21", name: "Côte-d'Or" },
  { code: "22", name: "Côtes-d'Armor" },
  { code: "23", name: "Creuse" },
  { code: "24", name: "Dordogne" },
  { code: "25", name: "Doubs" },
  { code: "26", name: "Drôme" },
  { code: "27", name: "Eure" },
  { code: "28", name: "Eure-et-Loir" },
  { code: "29", name: "Finistère" },
  { code: "30", name: "Gard" },
  { code: "31", name: "Haute-Garonne" },
  { code: "32", name: "Gers" },
  { code: "33", name: "Gironde" },
  { code: "34", name: "Hérault" },
  { code: "35", name: "Ille-et-Vilaine" },
  { code: "36", name: "Indre" },
  { code: "37", name: "Indre-et-Loire" },
  { code: "38", name: "Isère" },
  { code: "39", name: "Jura" },
  { code: "40", name: "Landes" },
  { code: "41", name: "Loir-et-Cher" },
  { code: "42", name: "Loire" },
  { code: "43", name: "Haute-Loire" },
  { code: "44", name: "Loire-Atlantique" },
  { code: "45", name: "Loiret" },
  { code: "46", name: "Lot" },
  { code: "47", name: "Lot-et-Garonne" },
  { code: "48", name: "Lozère" },
  { code: "49", name: "Maine-et-Loire" },
  { code: "50", name: "Manche" },
  { code: "51", name: "Marne" },
  { code: "52", name: "Haute-Marne" },
  { code: "53", name: "Mayenne" },
  { code: "54", name: "Meurthe-et-Moselle" },
  { code: "55", name: "Meuse" },
  { code: "56", name: "Morbihan" },
  { code: "57", name: "Moselle" },
  { code: "58", name: "Nièvre" },
  { code: "59", name: "Nord" },
  { code: "60", name: "Oise" },
  { code: "61", name: "Orne" },
  { code: "62", name: "Pas-de-Calais" },
  { code: "63", name: "Puy-de-Dôme" },
  { code: "64", name: "Pyrénées-Atlantiques" },
  { code: "65", name: "Hautes-Pyrénées" },
  { code: "66", name: "Pyrénées-Orientales" },
  { code: "67", name: "Bas-Rhin" },
  { code: "68", name: "Haut-Rhin" },
  { code: "69", name: "Rhône" },
  { code: "70", name: "Haute-Saône" },
  { code: "71", name: "Saône-et-Loire" },
  { code: "72", name: "Sarthe" },
  { code: "73", name: "Savoie" },
  { code: "74", name: "Haute-Savoie" },
  { code: "75", name: "Paris" },
  { code: "76", name: "Seine-Maritime" },
  { code: "77", name: "Seine-et-Marne" },
  { code: "78", name: "Yvelines" },
  { code: "79", name: "Deux-Sèvres" },
  { code: "80", name: "Somme" },
  { code: "81", name: "Tarn" },
  { code: "82", name: "Tarn-et-Garonne" },
  { code: "83", name: "Var" },
  { code: "84", name: "Vaucluse" },
  { code: "85", name: "Vendée" },
  { code: "86", name: "Vienne" },
  { code: "87", name: "Haute-Vienne" },
  { code: "88", name: "Vosges" },
  { code: "89", name: "Yonne" },
  { code: "90", name: "Territoire de Belfort" },
  { code: "91", name: "Essonne" },
  { code: "92", name: "Hauts-de-Seine" },
  { code: "93", name: "Seine-Saint-Denis" },
  { code: "94", name: "Val-de-Marne" },
  { code: "95", name: "Val-d'Oise" }
];



const TrouverDesDiffuseurs = () => {
  const [departement, setDepartement] = useState("");
  const [diffuseurs, setDiffuseurs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDiffuseur, setSelectedDiffuseur] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(''); 
  const [livres, setLivres] = useState([]); 
  const [selectedLivre, setSelectedLivre] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false); // Nouvel état
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const fetchLivresAuteurs = async () => {
    setError('');
    try {
        // const response = await fetch('http://localhost:8000/api/livres-Auteur', {
            const response = await fetch(`${BASE_URL}/livres-Auteur`, {

            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des livres.');
        }

        const data = await response.json();
        setLivres(data);
    } catch (error) {
        setError(error.message);
    }
};
const countries = ['France', 'Belgique', 'Luxembourg', 'Suisse'];
const cities = ['Paris', 'Lyon', 'Marseille'];
useEffect(() => {
  fetchLivresAuteurs();
}, []);

const handleModalOpen = () => {
  setIsModalOpen(true);
};

const handleModalClose = () => {
  setIsModalOpen(false);
};

const handleSubmit = async (e) => {
  e.preventDefault();

  if (!selectedLivre || !selectedDiffuseur) {
    alert("Veuillez sélectionner un livre et un diffuseur.");
    return;
  }

  const pitch = document.querySelector("textarea[name='pitch']").value;
  const resume = document.querySelector("textarea[name='resume']").value;
  const quantite_envoyee = document.querySelector("textarea[name='quantite_envoyee']").value;


  try {
    // const response = await fetch("http://localhost:8000/api/livres/assign", {
        const response = await fetch(`${BASE_URL}/livres/assign`, {

      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        livre_id: selectedLivre.id,
        diffuseur_id: selectedDiffuseur.id,
        pitch: pitch,
        resume: resume,
        quantite_envoyee: quantite_envoyee,
      }),
    });

    if (!response.ok) {
      throw new Error("Une erreur est survenue lors de l'envoi du livre.");
    }

    const data = await response.json();
    setIsModalOpen(false); // Ferme le modal principal
    setIsSuccess(true); // Affiche le modal de succès
  } catch (error) {
    console.error("Erreur lors de l'envoi du livre :", error);
    alert("Une erreur est survenue. Veuillez réessayer.");
  }
};




  const getCoordinatesFromAddress = async (address) => {
    const apiKey = "AIzaSyBbGmpRZdudt9VOiFSVJRTGEKjV-XG2h6o";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        console.error(`Geocoding error: ${data.status}`);
        return null;
      }
    } catch (error) {
      console.error("Geocoding API request error:", error);
      return null;
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    setError("");
  
    try {
      const token = localStorage.getItem("token");
      const params = new URLSearchParams();
      if (departement) params.append("departement", departement);
      if (selectedCountry) params.append("country", selectedCountry);
      if (selectedCity) params.append("ville", selectedCity);
  
      const response = await fetch(`${BASE_URL}/liste-diffuseurs?${params.toString()}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des diffuseurs.");
      }
  
      const data = await response.json();
      console.log("Données reçues :", data); // Vérifier la structure des données
  
      // Vérifiez que `diffuseurs` est un tableau
      if (Array.isArray(data.diffuseurs)) {
        // Obtenez les coordonnées pour les diffuseurs sans lat/lng
        const updatedDiffuseurs = await Promise.all(
          data.diffuseurs.map(async (diffuseur) => {
            if (!diffuseur.lat || !diffuseur.lng) {
              const coords = await getCoordinatesFromAddress(diffuseur.commune);
              if (coords) {
                return { ...diffuseur, lat: coords.lat, lng: coords.lng };
              }
            }
            return diffuseur; // Retourne le diffuseur tel quel s'il a déjà des coordonnées
          })
        );
  
        setDiffuseurs(updatedDiffuseurs); // Mettre à jour les diffuseurs avec leurs coordonnées
      } else {
        setDiffuseurs([]); // Si aucune liste de diffuseurs trouvée
        setError("Aucun diffuseur trouvé.");
      }
    } catch (error) {
      console.error("Erreur lors de la recherche :", error);
      setError(error.message || "Une erreur est survenue.");
    } finally {
      setLoading(false);
    }
  };
  



  
  
  
  const handleLivreChange = (e) => {
    const livreId = parseInt(e.target.value, 10);
    const selected = livres.find((livre) => livre.id === livreId);
    setSelectedLivre(selected || null);
  };

  const getInitials = (username, prenom) => {
    const firstInitial = username ? username[0].toUpperCase() : '';
    const secondInitial = prenom ? prenom[0].toUpperCase() : '';
    return `${firstInitial}${secondInitial}`;
  };
  

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
         {/* Sidebar */}
             <div className="bg-white shadow-md rounded-lg p-4">
<ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
                                <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes Informations
                                </a>
                            </li>
                            <li className="group">
    <a href="/recherchre-diffuseurs"className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes livres
    </a>
  </li>
 

 
   <li className="group">
    <a href="/devis-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" />
      </span>
      Devis Impression / demande
    </a>
  </li>

   {/* <li className="group">
     <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
       </span>
       Conseil communication
     </a>
   </li> */}
   <li className="group">
     <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
       </span>
       Vente de livres
     </a>
   </li>

   <li className="group">
    <a href="/stock" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
       </span>
     Gestion de Stock
    </a>
  </li>
  <li className="group">
                <a
                  href="/payments"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Mes paiements
                </a>
              </li>
 </ul>


             </div>

          {/* Main Content */}
          <div className="md:col-span-3">
            <h1 className="text-2xl font-bold mb-4">Trouver des Diffuseurs</h1>

            {/* Department selection */}
            <div className="mb-4">
  <label className="block font-bold text-[#737373] mb-2">Rechercher géographiquement :</label>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
    {/* Filtrer par département */}
    <select
      value={departement}
      onChange={(e) => setDepartement(e.target.value)}
      className="border border-gray-300 p-2 rounded-md"
    >
      <option value="">Tous les départements</option>
      {departements.map((dep) => (
        <option key={dep.code} value={dep.code}>
          {dep.code} - {dep.name}
        </option>
      ))}
    </select>

    {/* Filtrer par pays */}
    <select
      value={selectedCountry}
      onChange={(e) => setSelectedCountry(e.target.value)}
      className="border border-gray-300 p-2 rounded-md"
    >
      <option value="">Tous les pays</option> {/* Option pour tous les pays */}
      {countries.map((country) => (
        <option key={country} value={country}>
          {country}
        </option>
      ))}
    </select>

    {/* Filtrer par ville */}
    <select
      value={selectedCity}
      onChange={(e) => setSelectedCity(e.target.value)}
      className="border border-gray-300 p-2 rounded-md"
    >
      <option value="">Toutes les villes</option> {/* Option pour toutes les villes */}
      {cities.map((ville) => (
        <option key={ville} value={ville}>
          {ville}
        </option>
      ))}
    </select>

    {/* Bouton Rechercher */}
    <button
      onClick={handleSearch}
      className={`ml-4 px-4 py-2 rounded-md ${
        loading ? "bg-gray-500 cursor-not-allowed" : "bg-green-500 text-white hover:bg-green-600"
      }`}
      disabled={loading}
    >
      {loading ? "Recherche en cours..." : "Rechercher"}
    </button>
  </div>
</div>

            {/* Google Map */}
            <LoadScript googleMapsApiKey="AIzaSyBbGmpRZdudt9VOiFSVJRTGEKjV-XG2h6o">
              <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={6}>
                {diffuseurs.map((diffuseur) => (
                  <Marker
                    key={diffuseur.id}
                    position={{ lat: parseFloat(diffuseur.lat), lng: parseFloat(diffuseur.lng) }}
                    onClick={() => setSelectedDiffuseur(diffuseur)}
                  />
                ))}

                {selectedDiffuseur && (
                  <InfoWindow
                    position={{
                      lat: parseFloat(selectedDiffuseur.lat),
                      lng: parseFloat(selectedDiffuseur.lng),
                    }}
                    onCloseClick={() => setSelectedDiffuseur(null)}
                  >
                    <div>
                      <h2>{selectedDiffuseur.username}</h2>
                      <p><strong>Prénom:</strong> {selectedDiffuseur.prenom}</p>
                      <p><strong>Commune:</strong> {selectedDiffuseur.commune}</p>
                      <p><strong>Téléphone:</strong> {selectedDiffuseur.telephone}</p>
                      <p><strong>Date de naissance:</strong> {selectedDiffuseur.date_naissance}</p>
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>

            {/* Diffuseurs list */}
            {/* <h2 className="text-xl font-bold mt-8">Liste des Diffuseurs</h2> */}
            {diffuseurs.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
               {diffuseurs.map((diffuseur) => (
  <div key={diffuseur.id} className="border rounded-lg shadow-lg p-4 flex items-center">
    {diffuseur.photo_profil ? (
      <img
        // src={`http://localhost:8000/storage/${diffuseur.photo_profil}`}
        src={`https://editions7.com/admin/public/storage/${diffuseur.photo_profil}`}

        alt={diffuseur.username}
        className="w-24 h-24 rounded-full mr-4"
      />
    ) : (
      <div
        className="w-24 h-24 rounded-full mr-4 flex items-center justify-center bg-gray-300 text-white font-bold"
        style={{ fontSize: '1.5rem' }}
      >
        {getInitials(diffuseur.username, diffuseur.prenom)}
      </div>
    )}
    <div>
      <h3 className="text-lg font-semibold mb-1">
        {diffuseur.username} {diffuseur.prenom}
      </h3>
      <p className="text-gray-600 mb-2">
        <strong>Né le:</strong> {diffuseur.date_naissance}
      </p>
      <p className="text-gray-600 mb-1"><strong>Commune:</strong> {diffuseur.commune}</p>
      <p className="text-gray-600 mb-1"><strong>Téléphone:</strong> {diffuseur.telephone}</p>
      <button
        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        onClick={() => {
          setSelectedDiffuseur(diffuseur);
          handleModalOpen();
        }}
      >
        Choisir et Soumettre le Livre
      </button>
    </div>
  </div>
))}

              {isModalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
      <h2 className="text-xl font-bold mb-4">Partager votre fiche de livre</h2>
      <form onSubmit={(e) => handleSubmit(e)}>
        {/* Sélection du projet */}
        <div className="mb-4">
          <label className="block font-bold mb-2">Choisir un projet :</label>
          <select
            onChange={handleLivreChange}
            className="w-full border p-2 rounded-md"
            required
          >
            <option value="">Sélectionnez un projet</option>
            {livres.map((livre) => (
              <option key={livre.id} value={livre.id}>
                {livre.title}
              </option>
            ))}
          </select>
        </div>

        {/* Couverture du livre */}
        <div className="mb-4">
          <label className="block font-bold mb-2">Couverture du livre :</label>
          {selectedLivre && selectedLivre.cover_first ? (
            <img
              // src={`http://localhost:8000/storage/${selectedLivre.cover_first.replace(
              //   "http://localhost:8000/storage/",
              src={`https://editions7.com/admin/public/storage/${selectedLivre.cover_first.replace(
                "https://editions7.com/admin/public/storage",
                ""
              )}`}
              alt="Couverture"
              className="w-40 h-40 object-cover"
            />
          ) : (
            <p>Aucune couverture disponible.</p>
          )}
        </div>

                {/* Quantite */}
                <div className="mb-4">
  <label className="block font-bold mb-2">Quantité :</label>
  <textarea
    name="quantite_envoyee" 
    className="w-full border border-gray-300 rounded-md p-2"
    rows="2"
    required
  ></textarea>
</div>

        {/* Pitch */}
        <div className="mb-4">
  <label className="block font-bold mb-2">Pitch de vente :</label>
  <textarea
    name="pitch" 
    className="w-full border border-gray-300 rounded-md p-2"
    rows="2"
    required
  ></textarea>
</div>

        {/* resume */}

        <div className="mb-4">
  <label className="block font-bold mb-2">Résumé :</label>
  <textarea
    name="resume" // Ajout de l'attribut name
    className="w-full border border-gray-300 rounded-md p-2"
    rows="2"
    required
  >
    {selectedLivre?.resume || ""}
  </textarea>
</div>

        {/* Email du Diffuseur */}
        <div className="mb-4">
          <label className="block font-bold mb-2">Email du diffuseur :</label>
          <input
            type="email"
            className="w-full border border-gray-300 rounded-md p-2"
            value={selectedDiffuseur?.email || ""}
            readOnly
          />
        </div>

        {/* Boutons */}
        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        >
          Envoyer
        </button>
        <button
          type="button"
          className="ml-4 bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
          onClick={handleModalClose}
        >
          Annuler
        </button>
      </form>
    </div>
  </div>
)}


{isSuccess && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md text-center">
      <h2 className="text-xl font-bold mb-4 text-green-600">Succès !</h2>
      <p className="text-gray-700 mb-6">
        Le livre a été envoyé avec succès au  {selectedDiffuseur.prenom} {selectedDiffuseur.username}.
      </p>
      <button
        onClick={() => setIsSuccess(false)} // Ferme le modal
        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
      >
        OK
      </button>
    </div>
  </div>
)}



              </div>
            ) : (
              <p className="mt-4">{loading ? "Chargement..." : "Aucun diffuseur trouvé."}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrouverDesDiffuseurs;



