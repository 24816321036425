import React, { useEffect, useState } from "react";
import Header1 from "../diffuseurs/Header1";
import jsPDF from "jspdf";
import BASE_URL from '../../key';
import { useLocation } from "react-router-dom";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';


const Payments = () => {
  const [paiements, setPaiements] = useState([]);
  const [factureDetails, setFactureDetails] = useState(null); // Détails du paiement sélectionné
  const [isFactureModalOpen, setIsFactureModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const userRoles = ["AUTEUR", "DIFFUSEUR"]; 
  const location = useLocation();
  const [isAuteurOpen, setIsAuteurOpen] = useState(false);
  const [isDiffuseurOpen, setIsDiffuseurOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Récupérer les paiements de l'utilisateur connecté
  useEffect(() => {
    const fetchPaiements = async () => {
      try {
        const response = await fetch(`${BASE_URL}/paiements`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des paiements");
        }

        const data = await response.json();
        setPaiements(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPaiements();
  }, []);

  // Ouvrir le modal avec les détails du paiement sélectionné
  const openFactureModal = (paiement) => {
    console.log(paiement); // Debug : Vérifiez si `paiement.user` contient les données
    setFactureDetails(paiement); // Stocke les détails pour la modale
    setIsFactureModalOpen(true);
  };
  

  // Fermer le modal
  const closeFactureModal = () => {
    setIsFactureModalOpen(false);
    setFactureDetails(null);
  };

  // Générer un PDF
  const generatePDF = () => {
    if (!factureDetails) return;
  
    const doc = new jsPDF();
  
    // Titre
    doc.setFontSize(16);
    doc.text("Facture de Paiement", 14, 15);
  
    // Adresse d'EDITIONS7 (Alignée à droite)
    doc.setFontSize(12);
    const xRight = 200; // Position horizontale pour aligner à droite
    doc.text("EDITIONS7", xRight, 20, { align: "right" });
    doc.text("79 PASSAGE DE LA MOUSSIERE", xRight, 25, { align: "right" });
    doc.text("69700 LOIRE SUR RHONE", xRight, 30, { align: "right" });
  
    // Informations de l'utilisateur
    doc.setFontSize(12);
    doc.text(
      `Utilisateur : ${factureDetails.user?.prenom || "Non disponible"} ${
        factureDetails.user?.username || ""
      }`,
      14,
      40
    );
    doc.text(`Numéro de Paiement : ${factureDetails.numero_paiement}`, 14, 50);
    doc.text(
      `Date : ${
        factureDetails.date_paiement
          ? new Date(factureDetails.date_paiement).toLocaleDateString()
          : "Non disponible"
      }`,
      14,
      60
    );
  
    // Tableau des détails
    const tableColumn = [
      "Numéro",
      "Montant (€)",
      "Mode de Paiement",
      "Début Abonnement",
      "Fin Abonnement",
      "Description",
    ];
    const tableRows = [
      [
        factureDetails.numero_paiement,
        factureDetails.montant,
        factureDetails.mode_paiement,
        factureDetails.date_debut_abonnement
          ? new Date(factureDetails.date_debut_abonnement).toLocaleDateString()
          : "Non disponible",
        factureDetails.date_fin_abonnement
          ? new Date(factureDetails.date_fin_abonnement).toLocaleDateString()
          : "Non disponible",
        factureDetails.description || "Non disponible",
      ],
    ];
  
    doc.autoTable({
      startY: 70,
      head: [tableColumn],
      body: tableRows,
      theme: "grid", // Style du tableau
      styles: { fontSize: 10, halign: "center" }, // Centrer les textes dans les colonnes
    });
  
    // Pied de page
    doc.setFontSize(10);
    doc.text(
      "Editions7, 79 passage de la moussiere RCS LYON 392 354 202",
      105,
      doc.lastAutoTable.finalY + 10,
      { align: "center" }
    );
  
    // Sauvegarder le PDF
    doc.save(`Facture-${factureDetails.numero_paiement}.pdf`);
  };



  useEffect(() => {
    const path = location.pathname;

    // Set dropdown open state and highlight selected item
    if (["/livres-auteurs", "/recherche-pro", "/devis","/ventes","stocks",].includes(path)) {
      setIsAuteurOpen(true);
      setSelectedItem(path);
    } else if (["/points", "/recherche-auteurs", "/mes-ventes-factures"].includes(path)) {
      setIsDiffuseurOpen(true);
      setSelectedItem(path);
    }
  }, [location.pathname]);


  return (
    <div className="bg-white min-h-screen">
      <Header1 />
      <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                     {/* Sidebar */}
                     <div className="bg-white shadow-md rounded-lg p-4">
  <ul className="space-y-4">
    {/* Fonctionnalités Globales */}
    <li className="group">
      <a
        href="/compte-auteur-diffuseur"
        className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 hover:rounded-md focus:border focus:border-green-500"
      >
        <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
          <IoMdArrowDropright className="text-green-500 h-6 w-6" />
        </span>
        Tableau de bord
      </a>
    </li>
    <li className="group">
      <a
        href="/informations"
        className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 hover:rounded-md focus:border focus:border-green-500"
      >
        <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
          <IoMdArrowDropright className="text-green-500 h-6 w-6" />
        </span>
        Mes Informations
      </a>
    </li>
    <li className="group">
      <a
        href="/payment"
        className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 hover:rounded-md focus:border focus:border-green-500"
      >
        <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
          <IoMdArrowDropright className="text-green-500 h-6 w-6" />
        </span>
        Facture d'abonnement
      </a>
    </li>

    {/* Dropdown pour les fonctionnalités Auteur */}
    {userRoles.includes("AUTEUR") && (
      <li className="group">
        <div
          className="font-bold text-gray-600 flex items-center cursor-pointer"
          onClick={() => setIsAuteurOpen(!isAuteurOpen)}
        >
          <IoMdArrowDropdown
            className={`h-6 w-6 transition-transform duration-200 ${
              isAuteurOpen ? "rotate-180" : ""
            }`}
          />
          <span className="ml-2">Fonctionnalités Auteur</span>
        </div>
        {isAuteurOpen && (
          <ul className="pl-6 mt-2 space-y-2">
            <li className="group">
              <a
                href="/livres-auteurs"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Mes livres
              </a>
            </li>
            <li className="group">
              <a
                href="/recherche-pro"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Trouver des professionnels
              </a>
            </li>
            <li className="group">
              <a
                href="/devis"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Devis Impression/Mes demandes Devis
              </a>
            </li>

            <li className="group">
              <a
                href="/ventes"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Mes Ventes
              </a>
            </li>
            <li className="group">
              <a
                href="/stocks"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Gestion de Stock
               </a>
            </li>
          </ul>
        )}
      </li>
    )}

    {/* Dropdown pour les fonctionnalités Diffuseur */}
    {userRoles.includes("DIFFUSEUR") && (
      <li className="group">
        <div
          className="font-bold text-gray-600 flex items-center cursor-pointer"
          onClick={() => setIsDiffuseurOpen(!isDiffuseurOpen)}
        >
          <IoMdArrowDropdown
            className={`h-6 w-6 transition-transform duration-200 ${
              isDiffuseurOpen ? "rotate-180" : ""
            }`}
          />
          <span className="ml-2">Fonctionnalités Diffuseur</span>
        </div>
        {isDiffuseurOpen && (
          <ul className="pl-6 mt-2 space-y-2">
            <li className="group">
              <a
                href="/points"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Gestion de points de vente
              </a>
            </li>
            <li className="group">
              <a
                href="/recherche-auteurs"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Trouver des auteurs
              </a>
            </li>
            <li className="group">
              <a
                href="/mes-ventes-factures"
                className="font-bold text-gray-600 flex items-center px-2 py-2 rounded-md hover:border hover:border-green-500 focus:border focus:border-green-500"
              >
                <span className="hidden group-hover:inline-block focus:inline-block text-green-500 mr-2">
                  <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                </span>
                Mes Ventes/Factures
              </a>
            </li>
          </ul>
        )}
      </li>
    )}
  </ul>
</div>

         < div className="md:col-span-3">
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
        <h2 className="text-xl font-bold mb-4">Mes Paiements</h2>
        {loading ? (
          <p>Chargement...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : paiements.length > 0 ? (
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 p-2">Numéro</th>
                <th className="border border-gray-300 p-2">Montant</th>
                <th className="border border-gray-300 p-2">Date</th>
                <th className="border border-gray-300 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {paiements.map((paiement) => (
                <tr key={paiement.id}>
                  <td className="border border-gray-300 p-2">
                    {paiement.numero_paiement}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {paiement.montant} €
                  </td>
                  <td className="border border-gray-300 p-2">
                    {new Date(paiement.date_paiement).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-2">
                    <button
                      onClick={() => openFactureModal(paiement)}
                      className="px-4 py-2 bg-green-500 text-white rounded-lg"
                    >
                      Voir Facture
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Aucun paiement trouvé.</p>
        )}
      </div>
      </div>
      </div>
</div>
      {/* Modal de Facture */}
      {isFactureModalOpen && factureDetails && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-4/5">
            {/* Header */}
            <div className="flex justify-between items-start mb-6">
              <div>
              <p>
  <strong>Utilisateur :</strong>{" "}
  {factureDetails.user?.prenom
    ? `${factureDetails.user.prenom} ${factureDetails.user.username}`
    : "Non disponible"}
</p>


              </div>
              <div className="text-right">
                <p>
                  <strong>EDITIONS7</strong>
                  <br />
                  79 PASSAGE DE LA MOUSSIERE
                  <br />
                  69700 LOIRE SUR RHONE
                </p>
              </div>
            </div>

            {/* Facture Details */}
            <div className="text-left mb-6">
              <p>
                <strong>Facture N° :</strong>{" "}
                {factureDetails.numero_paiement || "Non disponible"}
              </p>
              <p>
                <strong>Date :</strong>{" "}
                {factureDetails.date_paiement
                  ? new Date(factureDetails.date_paiement).toLocaleDateString()
                  : "Date non disponible"}
              </p>
            </div>

            {/* Tableau des Détails */}
            <table className="table-auto w-full border-collapse border border-gray-400 mt-4">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 p-2">Numéro</th>
                  <th className="border border-gray-300 p-2">Montant</th>
                  <th className="border border-gray-300 p-2">Mode de Paiement</th>
                  <th className="border border-gray-300 p-2">Début Abonnement</th>
                  <th className="border border-gray-300 p-2">Fin Abonnement</th>
                  <th className="border border-gray-300 p-2">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.numero_paiement}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.montant} €
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.mode_paiement}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.date_debut_abonnement
                      ? new Date(
                          factureDetails.date_debut_abonnement
                        ).toLocaleDateString()
                      : "Non disponible"}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.date_fin_abonnement
                      ? new Date(
                          factureDetails.date_fin_abonnement
                        ).toLocaleDateString()
                      : "Non disponible"}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {factureDetails.description || "Non disponible"}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Footer */}
            <div className="text-center mt-6 text-sm text-gray-700">
                Editions7, 79 passage de la moussiere RCS LYON 392 354 202
            </div>
            <div className="text-right mt-6">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
                onClick={closeFactureModal}
              >
                Fermer
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-lg"
                onClick={generatePDF}
              >
                Télécharger en PDF
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payments;
