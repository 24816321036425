import React, { useState, useEffect } from 'react';
import { IoMdArrowDropright } from 'react-icons/io';
import Header1 from './diffuseurs/Header1';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../key';

const Stock = () => {
  const [username, setUsername] = useState(''); 
  const [stockData, setStockData] = useState([]); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }
    
        // const response = await fetch('http://localhost:8000/api/getStocks', {
          const response = await fetch(`${BASE_URL}/getStocks`, {

          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          console.log('Données retournées par l\'API:', data); // Vérifiez ce qui est retourné
          setStockData(data); // Stockez les données dans l'état
        } else {
          console.error('Failed to fetch stock data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };
    

    fetchStocks();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/login');
  };

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-white shadow-md rounded-lg p-4">
 <ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
    <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
Mes Informations    </a>
  </li>
  <li className="group">
    <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes livres
    </a>
  </li>


  <li className="group">
    <a href="/devis-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Devis Impression / demande
    </a>
  </li>
  {/* <li className="group">
    <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Conseil communication
    </a>
  </li> */}
  <li className="group">
    <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Vente de livres
    </a>
  </li>
  
  <li className="group">
    <a href="/stock" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
     Gestion de Stock
    </a>
  </li>

  <li className="group">
                <a
                  href="/payments"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Mes paiements
                </a>
              </li>
 </ul>


</div>

          {/* Main Section */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              {/* Welcome Message */}
              {/* <p className="text-lg mb-4">
                Bonjour <strong>{username || 'Utilisateur'}</strong> (vous n'êtes pas{' '}
                <strong>{username || 'Utilisateur'}</strong>?{' '}
                <span onClick={handleLogout} className="text-blue-600 cursor-pointer">
                  Déconnexion
                </span>)
              </p> */}

              {/* Main Section */}
              <h3 className="text-md font-bold mb-4">
                Stocks des Livres Envoyés
              </h3>

              {/* Stock Table */}
              <div className="overflow-x-auto shadow-lg sm:rounded-lg">
              <table className="min-w-full text-sm text-left text-gray-600">
  <thead className="bg-gray-200 text-gray-800">
    <tr>
      <th scope="col" className="px-6 py-3 text-center">Titre du Livre</th>
      <th scope="col" className="px-6 py-3 text-center">Stock Imprimé</th>
      <th scope="col" className="px-6 py-3 text-center">Stock Editions7</th>
      <th scope="col" className="px-6 py-3 text-center">Stock Envoyé</th>
      <th scope="col" className="px-6 py-3 text-center">Stock Auteur</th>
    </tr>
  </thead>
  <tbody>
  {stockData.length > 0 ? (
    stockData.map((stock, index) => (
      <tr key={index} className="border-b hover:bg-gray-100">
        <td className="px-6 py-4 text-center">{stock.livre}</td>
        <td className="px-6 py-4 text-center">{stock.stock_imprime}</td>
        <td className="px-6 py-4 text-center">{stock.stock_editions7}</td>
        <td className="px-6 py-4 text-center">{stock.stock_envoye}</td>
        <td className="px-6 py-4 text-center">{stock.stock_auteur}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="5" className="text-center py-4 text-gray-500">
        {stockData.length === 0 ? 'Chargement...' : 'Aucun stock disponible'}
      </td>
    </tr>
  )}
</tbody>

</table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stock;
