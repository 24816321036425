// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   username: '',
//   email: '',
//   password: '',
//   password_confirmation: '',
//   role: '',
//   photo_profil: null,
//   adresse_facturation: '',
//   commune: '',
//   code_postal: '',
//   telephone: '',
//   site_internet: '',
//   identifiant: '',
//   setEmailOrIdentifiant:'',
// };

// const inscriptionSlice = createSlice({
//   name: 'inscription',
//   initialState,
//   reducers: {
//     updateField: (state, action) => {
//       const { field, value } = action.payload;
//       state[field] = value;
//     },
//     resetFields: () => initialState, // Réinitialiser tous les champs
//   },
// });



// export const { updateField, resetFields, setSignedContract, nextStep } =
//   inscriptionSlice.actions;
// // export const { updateField, resetFields } = inscriptionSlice.actions;
// export default inscriptionSlice.reducer;



import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  email: '',
  password: '',
  password_confirmation: '',
  role: '',
  ville:'',
  prenom:'',
  country:'',
  photo_profil: null,
  adresse_facturation: '',
  commune: '',
  code_postal: '',
  telephone: '',
  site_internet: '',
  identifiant: '',
  setEmailOrIdentifiant: '',
  signedContract: false, // Indique si le contrat a été signé
  step: 1, // Étape actuelle d'inscription
};

const inscriptionSlice = createSlice({
  name: 'inscription',
  initialState,
  reducers: {
    updateField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    resetFields: () => initialState, // Réinitialiser tous les champs
    setSignedContract: (state) => {
      state.signedContract = true;
    },
    nextStep: (state) => {
      state.step += 1;
    },
  },
});

export const { updateField, resetFields, setSignedContract, nextStep } =
  inscriptionSlice.actions;

export default inscriptionSlice.reducer;
