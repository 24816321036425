// import React, { useState, useEffect } from 'react';
// import { IoMdArrowDropdown } from 'react-icons/io';
// import { IoMdArrowDropright } from 'react-icons/io';
// import Header1 from './Header1';
// import BASE_URL from '../../key';

// const INFOD = () => {
//   const [formData, setFormData] = useState({
//     civilite: '',
//     username: '',
//     prenom: '',
//     date_naissance: { day: '', month: '', year: '' },
//     email: '',
//     siret: '',
//     tva: '',
//     currentPassword: '',
//     newPassword: '',
//     confirmPassword: '',
//   });

//   const [successMessage, setSuccessMessage] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   useEffect(() => {
//     const fetchUserData = async () => {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         console.error('Token manquant');
//         return;
//       }
  
//       try {
//         // const response = await fetch('http://localhost:8000/api/user', {
//           const response = await fetch(`${BASE_URL}/user`, {

//           method: 'GET',
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json',
//           },
//         });
  
//         if (response.ok) {
//           const userData = await response.json();
//           console.log('Données utilisateur récupérées :', userData);
//           setFormData({
//             civilite: userData.civilite || '',
//             username: userData.username || '',
//             prenom: userData.prenom || '',
//             date_naissance: userData.date_naissance || { day: '', month: '', year: '' },
//             email: userData.email || '',
//             siret: userData.siret || '',
//             tva: userData.tva || '',
//           });
//         } else {
//           const errorData = await response.json();
//           console.error('Erreur API :', errorData);
//           setErrorMessage(errorData.message || 'Erreur lors de la récupération des données.');
//         }
//       } catch (error) {
//         console.error('Erreur réseau :', error);
//         setErrorMessage('Erreur réseau. Veuillez réessayer.');
//       }
//     };
  
//     fetchUserData();
//   }, []);
  
//   const handlePasswordChange = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     const payload = {
//         current_password: formData.currentPassword,
//         new_password: formData.newPassword,
//         new_password_confirmation: formData.confirmPassword,
//     };

//     try {
//         // const response = await fetch('http://localhost:8000/api/user/update-password', {
//           const response = await fetch(`${BASE_URL}/user/update-password`, {

//             method: 'PUT',
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(payload),
//         });

//         if (response.ok) {
//             setSuccessMessage('Mot de passe mis à jour avec succès.');
//             setErrorMessage('');
//         } else {
//             const errorData = await response.json();
//             setErrorMessage(errorData.error || 'Erreur lors de la mise à jour du mot de passe.');
//         }
//     } catch (error) {
//         setErrorMessage('Erreur réseau. Veuillez réessayer.');
//     }
// };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };
// const handleBirthDateChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({
//         ...prev,
//         date_naissance: {
//             ...prev.date_naissance,
//             [name]: value,
//         },
//     }));
// };






// const handleSubmit = async (e) => {
//   e.preventDefault();
//   const token = localStorage.getItem('token');

//   const { day, month, year } = formData.date_naissance;
//   const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

//   const payload = {
//       civilite: formData.civilite,
//       username: formData.username,
//       prenom: formData.prenom,
//       date_naissance: formattedDate,
//       email: formData.email,
//       siret: formData.siret,
//       tva: formData.tva,
//   };

//   try {
//       // const response = await fetch('http://localhost:8000/api/user/update', {
//         const response = await fetch(`${BASE_URL}/user/update`, {

//           method: 'PUT',
//           headers: {
//               Authorization: `Bearer ${token}`,
//               'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//       });

//       if (response.ok) {
//           setSuccessMessage('Informations mises à jour avec succès.');
//           setErrorMessage('');
//       } else {
//           const errorData = await response.json();
//           setErrorMessage(errorData.message || 'Erreur lors de la mise à jour.');
//       }
//   } catch (error) {
//       setErrorMessage('Erreur réseau. Veuillez réessayer.');
//   }
// };


//   return (
//     <div className="bg-white min-h-screen">
//       <Header1 />

//       <div className="container mx-auto px-4 py-8">
//         <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
//           {/* Sidebar */}
//           <div className="bg-white shadow-md rounded-lg p-4">
//                         <ul className="space-y-4">
//                             <li className="group">
//                                 <a href="/compte-diffuseur"  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//                                     <span className="hidden group-hover:inline-block text-green-500 mr-2">
//                                         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//                                     </span>
//                                     Tableau de bord
//                                 </a>
//                             </li>
                      
//                             <li className="group">
//                                 <a href="/information-diffuseur" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
//     <span className="inline-block text-green-500 mr-2">
//       <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
//     </span>
//                                     Mes Informations
//                                 </a>
//                             </li>
                              
//                             <li className="group">
//                                 <a href="/rechercher-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//                                     <span className="hidden group-hover:inline-block text-green-500 mr-2">
//                                         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//                                     </span>
//                                     Trouver des auteurs
//                                 </a>
//                             </li>
//                             <li className="group">
//                                 <a href="/points-ventes"className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//                                     <span className="hidden group-hover:inline-block text-green-500 mr-2">
//                                         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//                                     </span>
//                                     Gestion de points de vente 
//                                 </a>
//                             </li>
//                             {/* <li className="group">
//                                 <a href="/factures" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//                                     <span className="hidden group-hover:inline-block text-green-500 mr-2">
//                                         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
//                                     </span>
//                                     Mes Ventes/factures                                </a>
//                             </li> */}
    
//                     <li className="group">
//                                 <a href="/mes-ventes" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//                                     <span className="hidden group-hover:inline-block text-green-500 mr-2">
//                                         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
//                                     </span>
//                                     Mes Ventes/Factures
//                                 </a>
//                             </li>
//                             <li className="group">
//                 <a
//                   href="/factures"
//                   className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
//                 >
//                   <span className="hidden group-hover:inline-block text-green-500 mr-2">
//                     <IoMdArrowDropright className="text-green-500 h-6 w-6" />
//                   </span>
//                   Mes paiements
//                 </a>
//               </li>
//                  {/* <li className="group">
//                                 <a href="/commandes" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//                                     <span className="hidden group-hover:inline-block text-green-500 mr-2">
//                                         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
//                                     </span>
//                                     Commandes
//                                 </a>
//                             </li> */}
                         
//                         </ul>
//                     </div>

//           {/* Main Content */}
//           <div className="md:col-span-3">
//             <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
//               <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">MES INFORMATIONS</h2>

//               {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
//               {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

//               <form className="space-y-4" onSubmit={handleSubmit}>
//                 <div className="grid grid-cols-1 gap-6">
//                   {/* Civilité Section */}
//                   <div className="flex items-center space-x-4 gap-4">
//                     <label className="font-bold text-[#737373]">Civilité :</label>
//                     <label className="flex items-center text-[#737373] font-semibold">
//                       <input type="radio" name="civilite" value="Mr" checked={formData.civilite === 'Mr'} onChange={handleInputChange} className="mr-2" /> Mr.
//                     </label>
//                     <label className="flex items-center text-[#737373] font-semibold">
//                       <input type="radio" name="civilite" value="Mme" checked={formData.civilite === 'Mme'} onChange={handleInputChange} className="mr-2" /> Mme.
//                     </label>
//                   </div>

//                   {/* Nom and Prénom Section */}
//                   <div className="grid grid-cols-2 gap-4">
//                     <div className="flex items-center space-x-2">
//                       <label className="font-bold text-[#737373]">Nom :</label>
//                       <input type="text" name="username" value={formData.username} onChange={handleInputChange} className="border border-gray-400 p-2 flex-1 rounded-md w-[21.5rem]" />
//                     </div>
//                     <div className="flex items-center space-x-2">
//                       <label className="font-bold text-[#737373]">Prénom :</label>
//                       <input type="text" name="prenom" value={formData.prenom} onChange={handleInputChange} className="border border-gray-400 p-2 flex-1 rounded-md w-[21.5rem]" />
//                     </div>
//                   </div>

//                   {/* Date de naissance Section */}
//                   <div className="grid grid-cols-3 gap-4">
//     {/* Date de naissance Section */}
//     <div className="flex items-center space-x-2">
//         <label className="font-bold whitespace-nowrap text-[#737373]">Date de naissance :</label>
//         <div className="flex items-center space-x-2">
//             {/* Jour */}
//             <div className="relative w-28">
//                 <select
//                     name="day"
//                     value={formData.date_naissance.day}
//                     onChange={handleBirthDateChange}
//                     className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8"
//                 >
//                     <option value="">Jour</option>
//                     {Array.from({ length: 31 }, (_, i) => (
//                         <option key={i + 1} value={i + 1}>
//                             {i + 1}
//                         </option>
//                     ))}
//                 </select>
//                 <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
//                             </div>

//             {/* Mois */}
//             <div className="relative w-32">
//                 <select
//                     name="month"
//                     value={formData.date_naissance.month}
//                     onChange={handleBirthDateChange}
//                     className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8"
//                 >
//                     <option value="">Mois</option>
//                     {[
//                         'Janvier',
//                         'Février',
//                         'Mars',
//                         'Avril',
//                         'Mai',
//                         'Juin',
//                         'Juillet',
//                         'Août',
//                         'Septembre',
//                         'Octobre',
//                         'Novembre',
//                         'Décembre',
//                     ].map((month, index) => (
//                         <option key={index + 1} value={index + 1}>
//                             {month}
//                         </option>
//                     ))}
//                 </select>
//                 <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
//             </div>

//             {/* Année */}
//             <div className="relative w-28">
//                 <select
//                     name="year"
//                     value={formData.date_naissance.year}
//                     onChange={handleBirthDateChange}
//                     className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8"
//                 >
//                     <option value="">Année</option>
//                     {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((year) => (
//                         <option key={year} value={year}>
//                             {year}
//                         </option>
//                     ))}
//                 </select>
//                 <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
//             </div>
//         </div>
//     </div>
// </div>


//                   {/* Email Section */}
//                   <div className="flex items-center space-x-2 gap-3">
//                     <label className="font-bold whitespace-nowrap text-[#737373]">Email :</label>
//                     <input type="email" name="email" value={formData.email} onChange={handleInputChange} className="border border-gray-400 p-2 rounded-md w-[21.5rem]" />
//                   </div>

//                   {/* Siret Section */}
//                   <div className="flex items-center space-x-2 gap-4">
//                     <label className="font-bold whitespace-nowrap text-[#737373]">Siret :</label>
//                     <input type="number" name="siret" value={formData.siret} onChange={handleInputChange} className="border border-gray-400 p-2 rounded-md w-[21.5rem]" />
//                   </div>

//                   {/* TVA Section */}
//                  {/* TVA Section */}
// <div className="flex items-center space-x-4 gap-8">
//   <label className="font-bold text-[#737373]">TVA :</label>
//   <label className="flex items-center text-[#737373] font-semibold">
//     <input
//       type="radio"
//       name="tva"
//       value="assujetti" 
//       checked={formData.tva === 'assujetti'}
//       onChange={handleInputChange}
//       className="mr-2"
//     /> Assujetti
//   </label>
//   <label className="flex items-center ml-8 text-[#737373] font-semibold">
//     <input
//       type="radio"
//       name="tva"
//       value="non assujetti"
//       checked={formData.tva === 'non assujetti'}
//       onChange={handleInputChange}
//       className="mr-2"
//     /> Non assujetti
//   </label>
// </div>

//                 </div>

//                 <button type="submit" className="bg-green-600 text-white font-bold py-2 px-4 rounded-lg mt-4">
//                   Enregistrer
//                 </button>
//               </form>
//             </div>

//             {/* Password Change Section */}
//             <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
//     <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">Changer mot de passe :</h2>
//     {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
//     {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
//     <form className="space-y-4" onSubmit={handlePasswordChange}>
//         <div className="grid grid-cols-1 gap-6">
//             <div className="flex items-center">
//                 <label className="font-bold w-56 text-[#737373]">Mot de passe actuel :</label>
//                 <input
//                     type="password"
//                     name="currentPassword"
//                     value={formData.currentPassword}
//                     onChange={handleInputChange}
//                     className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
//                 />
//             </div>
//             <div className="flex items-center">
//                 <label className="font-bold w-56 text-[#737373]">Nouveau mot de passe :</label>
//                 <input
//                     type="password"
//                     name="newPassword"
//                     value={formData.newPassword}
//                     onChange={handleInputChange}
//                     className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
//                 />
//             </div>
//             <div className="flex items-center">
//                 <label className="font-bold w-56 text-[#737373]">Confirmez mot de passe :</label>
//                 <input
//                     type="password"
//                     name="confirmPassword"
//                     value={formData.confirmPassword}
//                     onChange={handleInputChange}
//                     className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
//                 />
//             </div>
//         </div>
//         <button type="submit" className="bg-green-600 text-white font-bold py-2 px-4 rounded-lg mt-4">
//             Changer
//         </button>
//     </form>
// </div>

//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default INFOD;




import React, { useState, useEffect } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { IoMdArrowDropright } from 'react-icons/io';
import Header1 from './Header1';
import BASE_URL from '../../key';

const INFOD = () => {
  const [formData, setFormData] = useState({
    civilite: '',
    username: '',
    prenom: '',
    date_naissance: { day: '', month: '', year: '' },
    email: '',
    siret: '',
    tva: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    photo_profil: '',
    adresse_facturation: '',
  });
  const [imagePreview, setImagePreview] = useState('');

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token manquant');
        return;
      }
  
      try {
        // const response = await fetch('http://localhost:8000/api/user', {
          const response = await fetch(`${BASE_URL}/user`, {

          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          const userData = await response.json();
          console.log('Données utilisateur récupérées :', userData);
          setFormData({
            civilite: userData.civilite || '',
            username: userData.username || '',
            prenom: userData.prenom || '',
            date_naissance: userData.date_naissance || { day: '', month: '', year: '' },
            email: userData.email || '',
            siret: userData.siret || '',
            tva: userData.tva || '',
            adresse_facturation: userData.adresse_facturation || '',
            photo_profil: userData.photo_profil || '',
          });
          if (userData.photo_profil) {
            setImagePreview(`${BASE_URL}/storage/${userData.photo_profil}`);
          }
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.message || 'Erreur lors de la récupération des données.');
        }
      } catch (error) {
        setErrorMessage('Erreur réseau. Veuillez réessayer.');
      }
    };

    fetchUserData();
  }, []);

  
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    const payload = {
        current_password: formData.currentPassword,
        new_password: formData.newPassword,
        new_password_confirmation: formData.confirmPassword,
    };

    try {
        // const response = await fetch('http://localhost:8000/api/user/update-password', {
          const response = await fetch(`${BASE_URL}/user/update-password`, {

            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            setSuccessMessage('Mot de passe mis à jour avec succès.');
            setErrorMessage('');
        } else {
            const errorData = await response.json();
            setErrorMessage(errorData.error || 'Erreur lors de la mise à jour du mot de passe.');
        }
    } catch (error) {
        setErrorMessage('Erreur réseau. Veuillez réessayer.');
    }
};

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };


  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'photo_profil' && files.length > 0) {
        const file = files[0];
        setFormData((prev) => ({ ...prev, photo_profil: file }));

        // Générer l'aperçu de la nouvelle photo
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    } else {
        setFormData((prev) => ({ ...prev, [name]: value }));
    }
};



const handleBirthDateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
        ...prev,
        date_naissance: {
            ...prev.date_naissance,
            [name]: value,
        },
    }));
};






// const handleSubmit = async (e) => {
//   e.preventDefault();
//   const token = localStorage.getItem('token');

//   const { day, month, year } = formData.date_naissance;
//   const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

//   const payload = {
//       civilite: formData.civilite,
//       username: formData.username,
//       prenom: formData.prenom,
//       date_naissance: formattedDate,
//       email: formData.email,
//       siret: formData.siret,
//       tva: formData.tva,
//   };

//   try {
//       // const response = await fetch('http://localhost:8000/api/user/update', {
//         const response = await fetch(`${BASE_URL}/user/update`, {

//           method: 'PUT',
//           headers: {
//               Authorization: `Bearer ${token}`,
//               'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//       });

//       if (response.ok) {
//           setSuccessMessage('Informations mises à jour avec succès.');
//           setErrorMessage('');
//       } else {
//           const errorData = await response.json();
//           setErrorMessage(errorData.message || 'Erreur lors de la mise à jour.');
//       }
//   } catch (error) {
//       setErrorMessage('Erreur réseau. Veuillez réessayer.');
//   }
// };

const handleSubmit = async (e) => {
  e.preventDefault();
  const token = localStorage.getItem('token');

  const { day, month, year } = formData.date_naissance;
  const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

  const payload = new FormData();
  payload.append('civilite', formData.civilite);
  payload.append('username', formData.username);
  payload.append('prenom', formData.prenom);
  payload.append('date_naissance', formattedDate);
  payload.append('email', formData.email);
  payload.append('siret', formData.siret);
  payload.append('tva', formData.tva);

  // Ajoutez la photo de profil si elle est un fichier
  if (formData.photo_profil instanceof File) {
      payload.append('photo_profil', formData.photo_profil);
  }

  try {
      const response = await fetch(`${BASE_URL}/user/update`, {
          method: 'PUT',
          headers: {
              Authorization: `Bearer ${token}`,
          },
          body: payload, // Utilisez FormData directement
      });

      if (response.ok) {
          const data = await response.json();
          setSuccessMessage(data.message);
          setImagePreview(data.photo_profil); // Chemin de la photo à afficher
          setErrorMessage('');
      } else {
          const errorData = await response.json();
          setErrorMessage(errorData.message || 'Erreur lors de la mise à jour.');
      }
  } catch (error) {
      setErrorMessage('Erreur réseau. Veuillez réessayer.');
  }
};


  return (
    <div className="bg-white min-h-screen">
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Sidebar */}
          <div className="bg-white shadow-md rounded-lg p-4">
                        <ul className="space-y-4">
                            <li className="group">
                                <a href="/compte-diffuseur"  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Tableau de bord
                                </a>
                            </li>
                      
                            <li className="group">
                                <a href="/information-diffuseur" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
                                    Mes Informations
                                </a>
                            </li>
                              
                            <li className="group">
                                <a href="/rechercher-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Trouver des auteurs
                                </a>
                            </li>
                            <li className="group">
                                <a href="/points-ventes"className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Gestion de points de vente 
                                </a>
                            </li>
                            {/* <li className="group">
                                <a href="/factures" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
                                    </span>
                                    Mes Ventes/factures                                </a>
                            </li> */}
    
                    <li className="group">
                                <a href="/mes-ventes" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
                                    </span>
                                    Mes Ventes/Factures   
                                </a>
                            </li>
                            <li className="group">
                <a
                  href="/factures"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                   Facture d'abonnement
                </a>
              </li>
                 {/* <li className="group">
                                <a href="/commandes" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
                                    </span>
                                    Commandes
                                </a>
                            </li> */}
                         
                        </ul>
                    </div>

          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">MES INFORMATIONS</h2>

              {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
              {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

              <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-6">
                  {/* Civilité Section */}
                  <div className="flex items-center space-x-4 gap-4">
                    <label className="font-bold text-[#737373]">Civilité :</label>
                    <label className="flex items-center text-[#737373] font-semibold">
                      <input type="radio" name="civilite" value="Mr" checked={formData.civilite === 'Mr'} onChange={handleInputChange} className="mr-2" /> M.
                    </label>
                    <label className="flex items-center text-[#737373] font-semibold">
                      <input type="radio" name="civilite" value="Mme" checked={formData.civilite === 'Mme'} onChange={handleInputChange} className="mr-2" /> Mme.
                    </label>
                  </div>

                  {/* Nom and Prénom Section */}
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center space-x-2">
                      <label className="font-bold text-[#737373]">Nom :</label>
                      <input type="text" name="username" value={formData.username} onChange={handleInputChange} className="border border-gray-400 p-2 flex-1 rounded-md w-[21.5rem]" />
                    </div>
                    <div className="flex items-center space-x-2">
                      <label className="font-bold text-[#737373]">Prénom :</label>
                      <input type="text" name="prenom" value={formData.prenom} onChange={handleInputChange} className="border border-gray-400 p-2 flex-1 rounded-md w-[21.5rem]" />
                    </div>
                  </div>
                  <div>
                    
    <label>Photo de profil:</label>
    {imagePreview && <img src={imagePreview} alt="Preview" className="w-20 h-20 rounded-full" />}
    <input type="file" name="photo_profil" onChange={handleInputChange} />
</div>

           
                  {/* Date de naissance Section */}
                  <div className="grid grid-cols-3 gap-4">
    {/* Date de naissance Section */}
    <div className="flex items-center space-x-2">
        <label className="font-bold whitespace-nowrap text-[#737373]">Date de naissance :</label>
        <div className="flex items-center space-x-2">
            {/* Jour */}
            <div className="relative w-28">
                <select
                    name="day"
                    value={formData.date_naissance.day}
                    onChange={handleBirthDateChange}
                    className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8"
                >
                    <option value="">Jour</option>
                    {Array.from({ length: 31 }, (_, i) => (
                        <option key={i + 1} value={i + 1}>
                            {i + 1}
                        </option>
                    ))}
                </select>
                <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
                            </div>

            {/* Mois */}
            <div className="relative w-32">
                <select
                    name="month"
                    value={formData.date_naissance.month}
                    onChange={handleBirthDateChange}
                    className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8"
                >
                    <option value="">Mois</option>
                    {[
                        'Janvier',
                        'Février',
                        'Mars',
                        'Avril',
                        'Mai',
                        'Juin',
                        'Juillet',
                        'Août',
                        'Septembre',
                        'Octobre',
                        'Novembre',
                        'Décembre',
                    ].map((month, index) => (
                        <option key={index + 1} value={index + 1}>
                            {month}
                        </option>
                    ))}
                </select>
                <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
            </div>

            {/* Année */}
            <div className="relative w-28">
                <select
                    name="year"
                    value={formData.date_naissance.year}
                    onChange={handleBirthDateChange}
                    className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8"
                >
                    <option value="">Année</option>
                    {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
                <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
            </div>
        </div>
    </div>
</div>


                  {/* Email Section */}
                  <div className="flex items-center space-x-2 gap-3">
                    <label className="font-bold whitespace-nowrap text-[#737373]">Email :</label>
                    <input type="email" name="email" value={formData.email} onChange={handleInputChange} className="border border-gray-400 p-2 rounded-md w-[21.5rem]" />
                  </div>

                  <div className="flex items-center space-x-2 gap-3">
              <label className="font-bold whitespace-nowrap text-[#737373]">Adresse de facturation:</label>
              <input
                type="text"
                name="adresse_facturation"
                value={formData.adresse_facturation}
                onChange={handleInputChange}
                className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
              />
            </div>
                  {/* Siret Section */}
                  <div className="flex items-center space-x-2 gap-4">
                    <label className="font-bold whitespace-nowrap text-[#737373]">Siret :</label>
                    <input type="number" name="siret" value={formData.siret} onChange={handleInputChange} className="border border-gray-400 p-2 rounded-md w-[21.5rem]" />
                  </div>

                  {/* TVA Section */}
                 {/* TVA Section */}
<div className="flex items-center space-x-4 gap-8">
  <label className="font-bold text-[#737373]">TVA :</label>
  <label className="flex items-center text-[#737373] font-semibold">
    <input
      type="radio"
      name="tva"
      value="assujetti" 
      checked={formData.tva === 'assujetti'}
      onChange={handleInputChange}
      className="mr-2"
    /> Assujetti
  </label>
  <label className="flex items-center ml-8 text-[#737373] font-semibold">
    <input
      type="radio"
      name="tva"
      value="non assujetti"
      checked={formData.tva === 'non assujetti'}
      onChange={handleInputChange}
      className="mr-2"
    /> Non assujetti
  </label>
</div>

                </div>

                <button type="submit" className="bg-green-600 text-white font-bold py-2 px-4 rounded-lg mt-4">
                  Enregistrer
                </button>
              </form>
            </div>

            {/* Password Change Section */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
    <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">Changer mot de passe :</h2>
    {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
    {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
    <form className="space-y-4" onSubmit={handlePasswordChange}>
        <div className="grid grid-cols-1 gap-6">
            <div className="flex items-center">
                <label className="font-bold w-56 text-[#737373]">Mot de passe actuel :</label>
                <input
                    type="password"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleInputChange}
                    className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
                />
            </div>
            <div className="flex items-center">
                <label className="font-bold w-56 text-[#737373]">Nouveau mot de passe :</label>
                <input
                    type="password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleInputChange}
                    className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
                />
            </div>
            <div className="flex items-center">
                <label className="font-bold w-56 text-[#737373]">Confirmez mot de passe :</label>
                <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
                />
            </div>
        </div>
        <button type="submit" className="bg-green-600 text-white font-bold py-2 px-4 rounded-lg mt-4">
            Changer
        </button>
    </form>
</div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default INFOD;
