import React from 'react';
import footerShape from '../img/footer.png'; // Ensure the correct path to the image
import { IoMdArrowDropright } from 'react-icons/io'; // Example of using a red arrow icon

const Footer = () => {
  return (
    <footer className="relative bg-[#00ae14] text-black">
      {/* Footer Wave Shape as Background */}
      <div className="absolute bottom-0 w-full 2xl:absolute 2xl:bottom-0 2xl:w-full xl:absolute xl:bottom-0 xl:w-full lg:absolute lg:bottom-0 lg:w-full md:absolute md:bottom-0 md:w-full">
        <img src={footerShape} alt="Footer Shape" className="w-full h-24 md:h-[13rem] lg:h-60 2xl:w-full 2xl:h-80 xl:w-full xl:h-80 lg:w-full  " />
      </div>

      {/* Footer Content centered */}
      <div className="relative z-10 py-16 flex flex-col justify-center items-center h-full text-center 2xl:relative 2xl:z-10 2xl:py-16 2xl:flex 2xl:flex-col 2xl:justify-center 2xl:items-center 2xl:h-full 2xl:text-center xl:relative xl:z-10 xl:py-16 xl:flex xl:flex-col xl:justify-center xl:items-center xl:h-full xl:text-center lg:relative lg:z-10 lg:py-16 lg:flex lg:flex-col lg:justify-center lg:items-center lg:h-full lg:text-center">
        <div className="container mx-auto  ">
          {/* Links with Even Larger Icons and Green Text */}
          <div className="mb-6 flex flex-col items-start  absolute left-0 pl-10 mt-32 ml-20 2xl:mb-6 2xl:flex 2xl:flex-col 2xl:items-start  2xl:absolute 2xl:left-0 2xl:pl-10 2xl:mt-32 2xl:ml-20 xl:mb-6 xl:flex xl:flex-col xl:items-start  xl:absolute xl:left-0 xl:pl-10 xl:mt-32 xl:ml-20 lg:mb-6 lg:flex lg:flex-col lg:items-start  lg:absolute lg:left-0  lg:mt-44 lg:ml-12 md:mb-6 md:flex md:flex-col md:items-start  md:absolute md:left-0  md:mt-44 md:ml-2">  {/* Adjusted position */}
            <div className="flex items-center -mt-12">
              <IoMdArrowDropright className="text-red-500 text-5xl mr-2 2xl:text-red-500 2xl:text-5xl 2xl:mr-2  xl:text-red-500 xl:text-5xl xl:mr-2 lg:text-red-500 lg:text-3xl lg:mr-2 md:text-red-500 md:text-2xl md:mr-2" /> {/* Larger Red arrow icon */}
              <a href="/conditions-generales" className="text-[#30bd40]  text-xl font-bold 2xl:text-xl  xl:text-xl  lg:text-lg md:text-sm  ">
                Conditions générales d'utilisation
              </a>
            </div>
            {/* <div className="flex items-center">
              <IoMdArrowDropright className="text-red-500 text-5xl mr-2 2xl:text-red-500 2xl:text-5xl 2xl:mr-2  xl:text-red-500 xl:text-5xl xl:mr-2 lg:text-red-500 lg:text-3xl lg:mr-2 md:text-red-500 md:text-2xl md:mr-2" /> 
              <a href="/rgpd" className="text-[#30bd40]  text-xl font-bold 2xl:text-xl  xl:text-xl  lg:text-lg md:text-sm  ">
                Charte RGPD
              </a>
            </div> */}
          </div>

          {/* Contact Information */}
          <div className="text-center mt-10 ">
        
          <div>
  <h4 className="font-bold mb-2">Nous Contacter :</h4>
  <div className="space-y-2">
    <p className="font-semibold">Cyril MOREL</p>
    <p className="font-semibold">
      <a
        href="mailto:contact@editions7.fr"
        className="hover:underline text-black ml-14"
      >
        contact@editions7.fr
      </a>
    </p>
  </div>
</div>

            {/* <p className='mb-2 font-semibold -ml-12'>06 85 39 96 94</p> */}
            </div>
            </div>
          </div>
        
    </footer>
  );
};

export default Footer;
