// import React, { useState, useEffect } from "react";
// import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
// import Header1 from "./diffuseurs/Header1";

// const ChatApp = () => {
//   const [users, setUsers] = useState([]);
//   const [groups, setGroups] = useState([{ id: 1, name: "Tchat Général" }]);
//   const [selectedGroup, setSelectedGroup] = useState("Tchat Général");
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");
//   const [newGroupName, setNewGroupName] = useState("");
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const fetchedUsers = [
//       { id: 1, name: "Alice", online: true },
//       { id: 2, name: "Bob", online: false },
//       { id: 3, name: "Charlie", online: true },
//     ];
//     setUsers(fetchedUsers);

//     if (selectedGroup === "Tchat Général") {
//       setMessages([
//         { sender: "Alice", content: "Bonjour tout le monde !" },
//         { sender: "Charlie", content: "Salut Alice !" },
//       ]);
//     }
//   }, [selectedGroup]);

//   const handleSendMessage = () => {
//     if (!newMessage.trim()) return;
//     setMessages([...messages, { sender: "Moi", content: newMessage }]);
//     setNewMessage("");
//   };

//   const handleCreateGroup = () => {
//     if (!newGroupName.trim() || selectedUsers.length === 0) return;
//     setGroups([...groups, { id: groups.length + 1, name: newGroupName }]);
//     setNewGroupName("");
//     setSelectedUsers([]);
//   };

//   const handleUserSelection = (userId) => {
//     if (selectedUsers.includes(userId)) {
//       setSelectedUsers(selectedUsers.filter((id) => id !== userId));
//     } else {
//       setSelectedUsers([...selectedUsers, userId]);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-gray-100">
//       <Header1 />
//       <div className="container mx-auto py-6">
//         <h1 className="text-3xl font-bold text-center mb-6">Chat App</h1>
//         <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
//           {/* Création de groupes */}
//           <div className="bg-white rounded-lg shadow-lg p-4 col-span-1">
//             <h2 className="text-xl font-semibold mb-4">Créer un Groupe</h2>
//             <input
//               type="text"
//               placeholder="Nom du groupe..."
//               value={newGroupName}
//               onChange={(e) => setNewGroupName(e.target.value)}
//               className="w-full p-2 border border-gray-300 rounded-md mb-4"
//             />
//             <h3 className="font-semibold mb-2">Sélectionnez les utilisateurs :</h3>
//             <ul className="space-y-2 max-h-40 overflow-y-scroll">
//               {users.map((user) => (
//                 <li key={user.id} className="flex items-center justify-between">
//                   <label className="flex items-center">
//                     <input
//                       type="checkbox"
//                       checked={selectedUsers.includes(user.id)}
//                       onChange={() => handleUserSelection(user.id)}
//                       className="mr-2"
//                     />
//                     {user.name}
//                   </label>
//                   <span
//                     className={`text-sm ${
//                       user.online ? "text-green-500" : "text-gray-400"
//                     }`}
//                   >
//                     {user.online ? "En ligne" : "Hors ligne"}
//                   </span>
//                 </li>
//               ))}
//             </ul>
//             <button
//               onClick={handleCreateGroup}
//               className="w-full bg-green-500 text-white px-4 py-2 mt-4 rounded-md hover:bg-green-600"
//             >
//               Créer le Groupe
//             </button>
//           </div>

//           {/* Fenêtre de Chat */}
//           <div className="bg-white rounded-lg shadow-lg p-4 col-span-3">
//             <h2 className="text-xl font-semibold mb-4">
//               Chat - {selectedGroup}
//             </h2>
//             <div className="h-96 overflow-y-scroll bg-gray-50 p-4 rounded-md">
//               {messages.map((msg, index) => (
//                 <div key={index} className="mb-4">
//                   <strong>{msg.sender}:</strong>
//                   <p>{msg.content}</p>
//                 </div>
//               ))}
//             </div>
//             <div className="mt-4 flex">
//               <input
//                 type="text"
//                 placeholder="Tapez votre message..."
//                 value={newMessage}
//                 onChange={(e) => setNewMessage(e.target.value)}
//                 className="flex-1 px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none"
//               />
//               <button
//                 onClick={handleSendMessage}
//                 className={`px-4 py-2 ${
//                   loading ? "bg-gray-400" : "bg-green-500"
//                 } text-white rounded-r-md hover:bg-green-600`}
//               >
//                 {loading ? "Chargement..." : "Envoyer"}
//               </button>
//             </div>
//           </div>
//         </div>

//         {/* Liste des groupes */}
//         <div className="mt-6 bg-white rounded-lg shadow-lg p-4">
//           <h2 className="text-xl font-semibold mb-4">Groupes</h2>
//           <ul className="flex flex-wrap gap-4">
//             {groups.map((group) => (
//               <li key={group.id}>
//                 <button
//                   onClick={() => setSelectedGroup(group.name)}
//                   className={`px-4 py-2 rounded-md ${
//                     selectedGroup === group.name
//                       ? "bg-green-500 text-white"
//                       : "bg-gray-200 text-gray-700"
//                   }`}
//                 >
//                   {group.name}
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatApp;


// import React, { useState, useEffect } from "react";
// import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
// import Header1 from "./diffuseurs/Header1";


// const ChatApp = () => {
//   const [members, setMembers] = useState([]);
//   const [groups, setGroups] = useState([]); 
//   const [selectedConversation, setSelectedConversation] = useState(null); 
//   const [messages, setMessages] = useState([]); 
//   const [newMessage, setNewMessage] = useState("");
//   const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
//   const [groupName, setGroupName] = useState(""); 
//   const [selectedMembers, setSelectedMembers] = useState([]); 
//   const [currentUserId, setCurrentUserId] = useState(null);  
  
//   const fetchAuthenticatedUser = async () => {
//     try {
//       const response = await fetch("http://localhost:8000/api/user", {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`, // Si vous utilisez un token d'authentification
//         },
//       });
//       if (response.ok) {
//         const data = await response.json();
//         setCurrentUserId(data.id); // Stocker seulement l'ID de l'utilisateur
//         console.log("Current User ID:", data.id); // Afficher l'ID dans la console
//       } else {
//         console.error("Failed to fetch user data");
//       }
//     } catch (error) {
//       console.error("Error fetching user data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchAuthenticatedUser(); 
//   }, []);
 


//   // Récupérer la liste des membres
//   // const fetchMembers = async () => {
//   //   try {
//   //     const response = await fetch("http://localhost:8000/api/members", {
//   //       headers: {
//   //         Authorization: `Bearer ${localStorage.getItem("token")}`,
//   //       },
//   //     });
//   //     const data = await response.json();
  
//   //     // Transformer en tableau valide
//   //     const membersArray = Array.isArray(data) ? data : Object.values(data);
//   //     setMembers(membersArray);
//   //   } catch (error) {
//   //     console.error("Erreur lors de la récupération des membres :", error);
//   //     setMembers([]); // Réinitialiser en cas d'erreur
//   //   }
//   // };
  

//   const fetchMembers = async () => {
//     try {
//       const response = await fetch("http://localhost:8000/api/members", {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       const data = await response.json();
  
//       const membersWithUnread = data.map((member) => ({
//         ...member,
//         unreadCount: member.unread_count || 0, // Nombre de messages non lus
//       }));
  
//       setMembers(membersWithUnread);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des membres :", error);
//     }
//   };
  
//   // Récupérer la liste des groupes
//   const fetchGroups = async () => {
//     try {
//       const response = await fetch("http://localhost:8000/api/groups", {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       const data = await response.json();
//       setGroups(data);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des groupes :", error);
//     }
//   };

//   const fetchMessages = async (conversationId, type = "private") => {
//     try {
//       const endpoint =
//         type === "group"
//           ? `http://localhost:8000/api/groups/${conversationId}/messages`
//           : `http://localhost:8000/api/messages/${conversationId}`;
//       const response = await fetch(endpoint, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       const data = await response.json();
//       setMessages(data);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des messages :", error);
//     }
//   };

//   const handleSendMessage = async () => {
//     if (!newMessage.trim()) {
//       alert("Le message ne peut pas être vide.");
//       return;
//     }

//     try {
//       const endpoint =
//         selectedConversation.type === "group"
//           ? `http://localhost:8000/api/groups/${selectedConversation.id}/send`
//           : "http://localhost:8000/api/messages/send";

//       const response = await fetch(endpoint, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           receiver_id: selectedConversation.type === "private" ? selectedConversation.id : undefined,
//           content: newMessage,
//         }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(
//           errorData.message || "Erreur lors de l'envoi du message."
//         );
//       }

//       const data = await response.json();
//       setMessages((prev) => [...prev, data.data]); // Ajouter le nouveau message
//       setNewMessage(""); // Réinitialiser le champ de saisie
//     } catch (error) {
//       console.error("Erreur lors de l'envoi du message :", error);
//     }
//   };

//   // Gérer la création d'un groupe
//   const handleCreateGroup = async () => {
//     if (!groupName.trim()) {
//       alert("Le nom du groupe ne peut pas être vide.");
//       return;
//     }

//     if (selectedMembers.length === 0) {
//       alert("Veuillez sélectionner au moins un membre.");
//       return;
//     }

//     try {
//       const response = await fetch("http://localhost:8000/api/groups/create", {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           name: groupName,
//           members: selectedMembers,
//         }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Erreur lors de la création du groupe.");
//       }

//       const data = await response.json();
//       setGroups((prev) => [...prev, data.group]);
//       setShowCreateGroupModal(false);
//       setGroupName("");
//       setSelectedMembers([]);
//     } catch (error) {
//       console.error("Erreur lors de la création du groupe :", error);
//     }
//   };

//   useEffect(() => {
//     fetchMembers();
//     fetchGroups();
//   }, []);

//   useEffect(() => {
//     if (selectedConversation) {
//       fetchMessages(selectedConversation.id, selectedConversation.type);
//     }
//   }, [selectedConversation]);
//   useEffect(() => {
//     console.log("Membres :", members);
//   }, [members]);
  
//   const markMessagesAsRead = async (conversationId) => {
//     try {
//       await fetch(`http://localhost:8000/api/messages/${conversationId}/mark-as-read`, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//         },
//       });
  
//       // Mettez à jour localement le nombre de messages non lus
//       setMembers((prev) =>
//         prev.map((member) =>
//           member.id === conversationId
//             ? { ...member, unreadCount: 0 }
//             : member
//         )
//       );
//     } catch (error) {
//       console.error("Erreur lors du marquage des messages comme lus :", error);
//     }
//   };
  


// import React, { useState, useEffect } from "react";
// import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
// import Header1 from "./diffuseurs/Header1";

// const ChatApp = () => {
//   const [members, setMembers] = useState([]);
//   const [groups, setGroups] = useState([]);
//   const [selectedConversation, setSelectedConversation] = useState(null);
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");
//   const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
//   const [groupName, setGroupName] = useState("");
//   const [selectedMembers, setSelectedMembers] = useState([]);
//   const [currentUserId, setCurrentUserId] = useState(null);

//   // Récupérer l'utilisateur authentifié
//   const fetchAuthenticatedUser = async () => {
//     try {
//       const response = await fetch("http://localhost:8000/api/user", {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       if (response.ok) {
//         const data = await response.json();
//         setCurrentUserId(data.id);
//       } else {
//         console.error("Échec de la récupération des informations utilisateur.");
//       }
//     } catch (error) {
//       console.error("Erreur lors de la récupération des informations utilisateur :", error);
//     }
//   };

//   useEffect(() => {
//     fetchAuthenticatedUser();
//   }, []);

//   // Récupérer la liste des membres et leurs messages non lus
//   const fetchMembers = async () => {
//     try {
//       const response = await fetch("http://localhost:8000/api/members", {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       const data = await response.json();

//       const membersWithUnread = data.map((member) => ({
//         ...member,
//         unreadCount: member.unread_count || 0, // Ajouter le compteur de messages non lus
//       }));

//       setMembers(membersWithUnread);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des membres :", error);
//     }
//   };

//   // Rafraîchir les messages non lus toutes les 2 minutes
//   useEffect(() => {
//     const fetchUnreadMessages = async () => {
//       try {
//         const response = await fetch("http://localhost:8000/api/messages/unread", {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         });
//         if (response.ok) {
//           const unreadData = await response.json();

//           setMembers((prev) =>
//             prev.map((member) => ({
//               ...member,
//               unreadCount: unreadData[member.id] || 0, // Mettre à jour les messages non lus
//             }))
//           );
//         } else {
//           console.error("Erreur lors de la récupération des messages non lus.");
//         }
//       } catch (error) {
//         console.error("Erreur réseau lors de la récupération des messages non lus :", error);
//       }
//     };

//     // Appel initial
//     fetchUnreadMessages();

//     // Intervalle toutes les 2 minutes
//     const interval = setInterval(fetchUnreadMessages, 120000);

//     return () => clearInterval(interval); // Nettoyage de l'intervalle
//   }, []);

//   // Récupérer la liste des groupes
//   const fetchGroups = async () => {
//     try {
//       const response = await fetch("http://localhost:8000/api/groups", {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       const data = await response.json();
//       setGroups(data);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des groupes :", error);
//     }
//   };

//   //   // Gérer la création d'un groupe
//   const handleCreateGroup = async () => {
//     if (!groupName.trim()) {
//       alert("Le nom du groupe ne peut pas être vide.");
//       return;
//     }

//     if (selectedMembers.length === 0) {
//       alert("Veuillez sélectionner au moins un membre.");
//       return;
//     }

//     try {
//       const response = await fetch("http://localhost:8000/api/groups/create", {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           name: groupName,
//           members: selectedMembers,
//         }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Erreur lors de la création du groupe.");
//       }

//       const data = await response.json();
//       setGroups((prev) => [...prev, data.group]);
//       setShowCreateGroupModal(false);
//       setGroupName("");
//       setSelectedMembers([]);
//     } catch (error) {
//       console.error("Erreur lors de la création du groupe :", error);
//     }
//   };

//   // Récupérer les messages d'une conversation
//   const fetchMessages = async (conversationId, type = "private") => {
//     try {
//       const endpoint =
//         type === "group"
//           ? `http://localhost:8000/api/groups/${conversationId}/messages`
//           : `http://localhost:8000/api/messages/${conversationId}`;
//       const response = await fetch(endpoint, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       const data = await response.json();
//       setMessages(data);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des messages :", error);
//     }
//   };

//   // Marquer les messages comme lus
//   const markMessagesAsRead = async (conversationId) => {
//     try {
//       await fetch(`http://localhost:8000/api/messages/${conversationId}/mark-as-read`, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//         },
//       });

//       // Mettre à jour localement les messages non lus
//       setMembers((prev) =>
//         prev.map((member) =>
//           member.id === conversationId ? { ...member, unreadCount: 0 } : member
//         )
//       );
//     } catch (error) {
//       console.error("Erreur lors du marquage des messages comme lus :", error);
//     }
//   };

//   // Envoyer un message
//   const handleSendMessage = async () => {
//     if (!newMessage.trim()) {
//       alert("Le message ne peut pas être vide.");
//       return;
//     }

//     try {
//       const endpoint =
//         selectedConversation.type === "group"
//           ? `http://localhost:8000/api/groups/${selectedConversation.id}/send`
//           : "http://localhost:8000/api/messages/send";

//       const response = await fetch(endpoint, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           receiver_id: selectedConversation.type === "private" ? selectedConversation.id : undefined,
//           content: newMessage,
//         }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Erreur lors de l'envoi du message.");
//       }

//       const data = await response.json();
//       setMessages((prev) => [...prev, data.data]); // Ajouter le nouveau message
//       setNewMessage("");
//     } catch (error) {
//       console.error("Erreur lors de l'envoi du message :", error);
//     }
//   };

//   useEffect(() => {
//     fetchMembers();
//     fetchGroups();
//   }, []);

//   useEffect(() => {
//     if (selectedConversation) {
//       fetchMessages(selectedConversation.id, selectedConversation.type);
//       markMessagesAsRead(selectedConversation.id); // Marquer comme lus
//     }
//   }, [selectedConversation]);
// return (
//     <div>
//       {/* En-tête */}
//       <Header1 />

//       {/* Conteneur principal */}
//       <div className="flex h-screen">
//       <div className="w-1/4 bg-gray-200 p-4 overflow-y-auto">
//       <h2 className="text-lg font-bold mb-4 flex items-center">
//   <IoMdArrowDropright className="mr-2" />
//   Mes Membres
// </h2>
// {Array.isArray(members) && members.map((member) => (
//   // <div
//   //   key={member.id}
//   //   className={`p-2 mb-2 cursor-pointer rounded-md flex items-center space-x-3 ${
//   //     selectedConversation?.id === member.id && selectedConversation.type === "private"
//   //       ? "bg-green-500 text-white"
//   //       : "bg-white"
//   //   }`}
//   //   onClick={() => setSelectedConversation({ id: member.id, type: "private" })}
//   // >
//   //   {/* Affichage de la photo du membre en cercle */}
//   //   <img
//   //     src={
//   //       member.photo_profil
//   //         ? `http://localhost:8000/storage/${member.photo_profil}`
//   //         : "/default-avatar.jpg"
//   //     }
//   //     alt={`${member.prenom} ${member.username}`}
//   //     className="w-10 h-10 rounded-full object-cover"  // Image en forme de cercle
//   //   />
//   //   <div className="flex-1">
//   //     <p className="font-semibold">{member.prenom} {member.username}</p>
      
//   //     {/* Affichage du message non vu */}
//   //     {selectedConversation?.id === member.id && selectedConversation.type === "private" && 
//   //       messages.some(msg => msg.sender_id === member.id && msg.receiver_id === currentUserId && msg.read_at === null) && (
//   //       <p className="text-xs text-gray-500">Vous avez envoyé un message</p>
//   //     )}
//   //   </div>
//   // </div>
//   <div
//   key={member.id}
//   className={`p-2 mb-2 cursor-pointer rounded-md flex items-center space-x-3 ${
//     selectedConversation?.id === member.id && selectedConversation.type === "private"
//       ? "bg-green-500 text-white"
//       : "bg-white"
//   }`}
//   onClick={() => {
//     setSelectedConversation({ id: member.id, type: "private" });
//     markMessagesAsRead(member.id); // Appeler la fonction pour marquer les messages comme lus
//   }}
// >
//   <img
//     src={
//       member.photo_profil
//         ? `http://localhost:8000/storage/${member.photo_profil}`
//         : "/default-avatar.jpg"
//     }
//     alt={`${member.prenom} ${member.username}`}
//     className="w-10 h-10 rounded-full object-cover"
//   />
//   <div className="flex-1">
//     <p className="font-semibold">{member.prenom} {member.username}</p>
//     {member.unreadCount > 0 && (
//       <span className="text-sm text-red-500 font-bold">
//         {member.unreadCount} nouveau(x) message(s)
//       </span>
//     )}
//   </div>
// </div>

// ))}


//   <h2 className="text-lg font-bold mt-6 mb-4 flex items-center">
//     <IoMdArrowDropright className="mr-2" />
//     Mes Groupes
//   </h2>
//   {groups.map((group) => (
//     <div
//       key={group.id}
//       className={`p-2 mb-2 cursor-pointer rounded-md ${
//         selectedConversation?.id === group.id && selectedConversation.type === "group"
//           ? "bg-green-500 text-white"
//           : "bg-white"
//       }`}
//       onClick={() => setSelectedConversation({ id: group.id, type: "group" })}
//     >
//       <p className="font-semibold">{group.name}</p>
//     </div>
//   ))}
//   <button
//     onClick={() => setShowCreateGroupModal(true)}
//     className="bg-green-500 text-white mt-4 px-4 py-2 rounded-md"
//   >
//     + Créer un Groupe
//   </button>
// </div>

//         {/* Zone de discussion */}
//         <div className="w-3/4 flex flex-col">
//           {selectedConversation ? (
//             <>
//               {/* En-tête de la discussion */}
//               <div className="bg-gray-300 p-4">
//                 <h2 className="text-lg font-bold">
//                   {selectedConversation.type === "group"
//                     ? `Groupe : ${selectedConversation.username}`
//                     : `Discussion avec ${selectedConversation.username}`}
//                 </h2>
//               </div>

//               {/* Messages */}
//              {/* Affichage des messages */}
//   <div className="flex-1 overflow-y-auto p-4 space-y-4">
//   {messages.length > 0 ? (
//     messages.map((msg, idx) => (
//       <div
//         key={idx}
//         className={`flex ${
//           msg.sender_id === currentUserId ? "justify-end" : "justify-start"
//         }`}
//       >
//         {/* Afficher l'image uniquement pour les messages reçus */}
//         {msg.sender_id !== currentUserId && (
//           <img
//             src={`http://localhost:8000/storage/${msg.sender_photo}`}
//             alt={`${msg.sender_prenom} ${msg.sender_username}`}
//             className="w-10 h-10 rounded-full object-cover mr-2" // Image en cercle
//           />
//         )}

//         <div
//           className={`max-w-xs p-3 rounded-lg ${
//             msg.sender_id === currentUserId
//               ? "bg-green-200 text-gray-800"
//               : "bg-gray-200 text-gray-800"
//           }`}
//         >
//           {/* Affichage du nom de l'expéditeur */}
//           <p className="text-sm font-semibold mb-1">
//             {msg.sender_id === currentUserId
//               ? "Vous"
//               : `${msg.sender_prenom} ${msg.sender_username}`}
//           </p>

//           {/* Contenu du message */}
//           <p>{msg.content}</p>

//           {/* Affichage de l'heure du message */}
//           <p className="text-xs text-gray-500 mt-1 text-right">
//             {new Date(msg.created_at).toLocaleString()}
//           </p>
//         </div>
//       </div>
//     ))
//   ) : (
//     <p>Aucun message dans cette discussion.</p>
//   )}
// </div>


              

//               {/* Envoi de message */}
//               <div className="bg-gray-300 p-4 flex items-center">
//                 <input
//                   type="text"
//                   value={newMessage}
//                   onChange={(e) => setNewMessage(e.target.value)}
//                   className="flex-1 p-2 border border-gray-300 rounded-md"
//                   placeholder="Écrivez un message..."
//                 />
//                 <button
//                   onClick={handleSendMessage}
//                   className="ml-4 bg-green-500 text-white px-4 py-2 rounded-md"
//                 >
//                   Envoyer
//                 </button>
//               </div>
//             </>
//           ) : (
//             <div className="flex-1 flex items-center justify-center">
//               <p>Sélectionnez une discussion ou un groupe pour afficher les messages.</p>
//             </div>
//           )}
//         </div>
//       </div>

//       {/* Modal de création de groupe */}
//       {showCreateGroupModal && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//           <div className="bg-white p-6 rounded-lg w-1/3">
//             <h2 className="text-lg font-bold mb-4">Créer un Groupe</h2>
//             <input
//               type="text"
//               value={groupName}
//               onChange={(e) => setGroupName(e.target.value)}
//               className="w-full p-2 border border-gray-300 rounded-md mb-4"
//               placeholder="Nom du groupe"
//             />
//             <h3 className="font-bold mb-2">Ajouter des membres :</h3>
//             <div className="max-h-40 overflow-y-auto">
//               {members.map((member) => (
//                 <div key={member.id} className="flex items-center mb-2">
//                   <input
//                     type="checkbox"
//                     value={member.id}
//                     onChange={(e) => {
//                       if (e.target.checked) {
//                         setSelectedMembers((prev) => [...prev, member.id]);
//                       } else {
//                         setSelectedMembers((prev) =>
//                           prev.filter((id) => id !== member.id)
//                         );
//                       }
//                     }}
//                   />
//                   <p className="ml-2">{member.name}</p>
//                 </div>
//               ))}
//             </div>
//             <div className="flex justify-end mt-4">
//               <button
//                 onClick={() => setShowCreateGroupModal(false)}
//                 className="mr-4 bg-gray-500 text-white px-4 py-2 rounded-md"
//               >
//                 Annuler
//               </button>
//               <button
//                 onClick={handleCreateGroup}
//                 className="bg-green-500 text-white px-4 py-2 rounded-md"
//               >
//                 Créer
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ChatApp;




// import React, { useState, useEffect } from "react";
// import { IoMdArrowDropright } from "react-icons/io";
// import Header1 from "./diffuseurs/Header1";

// const ChatApp = () => {
//   const [members, setMembers] = useState([]);
//   const [selectedConversation, setSelectedConversation] = useState(null);
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");
//   const [currentUserId, setCurrentUserId] = useState(null);
//   const getInitials = (prenom, username) => {
//     const firstInitial = prenom ? prenom[0].toUpperCase() : "";
//     const secondInitial = username ? username[0].toUpperCase() : "";
//     return `${firstInitial}${secondInitial}`;
//   };
  

//   /** Récupérer l'utilisateur authentifié */
//   const fetchAuthenticatedUser = async () => {
//     try {
//       const response = await fetch("http://localhost:8000/api/user", {
//             // const response = await fetch("https://editions7.com/admin/public/api/user", {

//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       });
//       if (response.ok) {
//         const data = await response.json();
//         setCurrentUserId(data.id);
//       } else {
//         console.error("Erreur lors de la récupération des informations utilisateur.");
//       }
//     } catch (error) {
//       console.error("Erreur réseau :", error);
//     }
//   };

//   /** Récupérer la liste des membres avec les messages non lus */
//   const fetchMembers = async () => {
//     try {
//       const response = await fetch("http://localhost:8000/api/members", {
//             // const response = await fetch("https://editions7.com/admin/public/api/members", {

//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       });
//       const data = await response.json();
//       const updatedMembers = data.map((member) => ({
//         ...member,
//         unreadCount: member.unread_count || 0,
//       }));
//       setMembers(updatedMembers);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des membres :", error);
//     }
//   };

//   /** Récupérer les messages de la conversation sélectionnée */
//   const fetchMessages = async (conversationId) => {
//     try {
//       const response = await fetch(
//         `http://localhost:8000/api/messages/${conversationId}`,
//                 // `https://editions7.com/admin/public/api/messages/${conversationId}`,

//         {
//           headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//         }
//       );
//       const data = await response.json();
//       setMessages(data);
//     } catch (error) {
//       console.error("Erreur lors de la récupération des messages :", error);
//     }
//   };

//   /** Marquer les messages comme lus */
//   const markMessagesAsRead = async (conversationId) => {
//     try {
//       await fetch(`http://localhost:8000/api/messages/${conversationId}/mark-as-read`, {
//             // await fetch(`https://editions7.com/admin/public/api/messages/${conversationId}/mark-as-read`, {

//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//         },
//       });

//       setMembers((prev) =>
//         prev.map((member) =>
//           member.id === conversationId ? { ...member, unreadCount: 0 } : member
//         )
//       );
//     } catch (error) {
//       console.error("Erreur lors du marquage des messages comme lus :", error);
//     }
//   };

//   /** Envoyer un message */
//   const handleSendMessage = async () => {
//     if (!newMessage.trim()) return;

//     try {
//       await fetch("http://localhost:8000/api/messages/send", {
//             // await fetch("https://editions7.com/admin/public/api/messages/send", {

//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           receiver_id: selectedConversation.id,
//           content: newMessage,
//         }),
//       });

//       setMessages((prev) => [...prev, { sender_id: currentUserId, content: newMessage }]);
//       setNewMessage("");
//       fetchMembers(); 
//     } catch (error) {
//       console.error("Erreur lors de l'envoi du message :", error);
//     }
//   };

//   /** Sélectionner une conversation */
//   const handleSelectConversation = (member) => {
//     setSelectedConversation({ id: member.id, username: member.username, prenom: member.prenom });
//     fetchMessages(member.id);
//     markMessagesAsRead(member.id);
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       fetchMembers();
//     }, 60000);

//     return () => clearInterval(interval); 
//   }, []);

//   useEffect(() => {
//     fetchAuthenticatedUser();
//     fetchMembers();
//   }, []);

//   return (
//     <div>
//       {/* En-tête */}
//       <Header1 />
//       <div className="flex h-screen">
//         {/* Section des membres */}
//         <div className="w-1/4 bg-gray-200 p-4 overflow-y-auto">
//           <h2 className="text-lg font-bold mb-4 flex items-center">
//             <IoMdArrowDropright className="mr-2" /> Mes Membres
//           </h2>
//           {members.map((member) => (
//             <div
//             key={member.id}
//             className={`p-2 mb-2 cursor-pointer rounded-md flex items-center space-x-3 ${
//               selectedConversation?.id === member.id ? "bg-green-500 text-white" : "bg-white"
//             }`}
//             onClick={() => handleSelectConversation(member)}
//           >
//             {member.photo_profil ? (
//               <img
//                 src={`http://localhost:8000/storage/${member.photo_profil}`}
//                 alt={`${member.prenom} ${member.username}`}
//                 className="w-10 h-10 rounded-full object-cover"
//               />
//             ) : (
//               <div
//                 className="w-10 h-10 flex items-center justify-center bg-gray-500 text-white font-bold rounded-full"
//                 style={{ fontSize: "0.875rem" }}
//               >
//                 {getInitials(member.prenom, member.username)}
//               </div>
//             )}
//             <div className="flex-1">
//               <p className="font-semibold">
//                 {member.prenom} {member.username}
//               </p>
//             </div>
//             {member.unreadCount > 0 && (
//               <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-full">
//                 {member.unreadCount}
//               </span>
//             )}
//           </div>
          
//           ))}
//         </div>

//         {/* Section des messages */}
//         <div className="w-3/4 flex flex-col">
//           {selectedConversation ? (
//             <>
//               <div className="bg-gray-300 p-4">
//                 <h2 className="text-lg font-bold">
//                   Discussion avec {selectedConversation.prenom} {selectedConversation.username}
//                 </h2>
//               </div>
//               <div className="flex-1 overflow-y-auto p-4 space-y-4">
//               {messages.length > 0 ? (
//     messages.map((msg, idx) => (
//       <div
//         key={idx}
//         className={`flex ${
//           msg.sender_id === currentUserId ? "justify-end" : "justify-start"
//         }`}
//       >
//         {/* Afficher l'image uniquement pour les messages reçus */}
//         {msg.sender_id !== currentUserId && (
//   msg.sender_photo ? (
//     <img
//       src={`http://localhost:8000/storage/${msg.sender_photo}`}
//       alt={`${msg.sender_prenom} ${msg.sender_username}`}
//       className="w-10 h-10 rounded-full object-cover mr-2"
//     />
//   ) : (
//     <div
//       className="w-10 h-10 flex items-center justify-center bg-gray-500 text-white font-bold rounded-full mr-2"
//       style={{ fontSize: "0.875rem" }}
//     >
//       {getInitials(msg.sender_prenom, msg.sender_username)}
//     </div>
//   )
// )}


//         <div
//           className={`max-w-xs p-3 rounded-lg ${
//             msg.sender_id === currentUserId
//               ? "bg-green-200 text-gray-800"
//               : "bg-gray-200 text-gray-800"
//           }`}
//         >
//           {/* Affichage du nom de l'expéditeur */}
//           <p className="text-sm font-semibold mb-1">
//             {msg.sender_id === currentUserId
//               ? "Vous"
//               : `${msg.sender_prenom} ${msg.sender_username}`}
//           </p>

//           {/* Contenu du message */}
//           <p>{msg.content}</p>

//           {/* Affichage de l'heure du message */}
//           <p className="text-xs text-gray-500 mt-1 text-right">
//             {new Date(msg.created_at).toLocaleString()}
//           </p>
//         </div>
//       </div>
//     ))
//   ) : (
//     <p>Aucun message dans cette discussion.</p>
//   )}
//               </div>
//               <div className="bg-gray-300 p-4 flex items-center">
//                 <input
//                   type="text"
//                   value={newMessage}
//                   onChange={(e) => setNewMessage(e.target.value)}
//                   className="flex-1 p-2 border border-gray-300 rounded-md"
//                   placeholder="Écrivez un message..."
//                 />
//                 <button
//                   onClick={handleSendMessage}
//                   className="ml-4 bg-green-500 text-white px-4 py-2 rounded-md"
//                 >
//                   Envoyer
//                 </button>
//               </div>
//             </>
//           ) : (
//             <div className="flex-1 flex items-center justify-center">
//               <p>Sélectionnez un membre pour afficher les messages.</p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatApp;


import React, { useState, useEffect } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import Header1 from "./diffuseurs/Header1";
import BASE_URL from "../key";

const ChatApp = () => {
  const [members, setMembers] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentUserId, setCurrentUserId] = useState(null);

  const getInitials = (prenom, username) => {
    const firstInitial = prenom && prenom.length > 0 ? prenom[0].toUpperCase() : "?";
    const secondInitial = username && username.length > 0 ? username[0].toUpperCase() : "?";
    return `${firstInitial}${secondInitial}`;
  };
  
  /** Fetch authenticated user */
  const fetchAuthenticatedUser = async () => {
    try {
      // const response = await fetch("http://localhost:8000/api/user", {
        const response = await fetch(`${BASE_URL}/user`, {

        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.ok) {
        const data = await response.json();
        setCurrentUserId(data.id);
      } else {
        console.error("Failed to fetch authenticated user.");
      }
    } catch (error) {
      console.error("Network error while fetching authenticated user:", error);
    }
  };

  /** Fetch members */
  const fetchMembers = async () => {
    try {
      // const response = await fetch("http://localhost:8000/api/members", {
        const response = await fetch(`${BASE_URL}/members`, {


        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = await response.json();
      const updatedMembers = data.map((member) => ({
        ...member,
        unreadCount: member.unread_count || 0,
      }));
      setMembers(updatedMembers);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  /** Fetch messages for selected conversation */
  const fetchMessages = async (conversationId) => {
    try {
      const response = await fetch(
        // `http://localhost:8000/api/messages/${conversationId}`,
        `${BASE_URL}/messages/${conversationId}`,

        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  /** Mark messages as read */
  const markMessagesAsRead = async (conversationId) => {
    try {
      // await fetch(`http://localhost:8000/api/messages/${conversationId}/mark-as-read`, {
        await fetch(`${BASE_URL}/messages/${conversationId}/mark-as-read`, {

        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === conversationId ? { ...member, unreadCount: 0 } : member
        )
      );
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  /** Handle sending a new message */
  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      // await fetch("http://localhost:8000/api/messages/send", {
        await fetch(`${BASE_URL}/messages/send`, {

        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          receiver_id: selectedConversation.id,
          content: newMessage,
        }),
      });
      setMessages((prev) => [...prev, { sender_id: currentUserId, content: newMessage }]);
      setNewMessage("");
      fetchMembers(); // Refresh member unread counts
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  /** Handle selecting a conversation */
  const handleSelectConversation = (member) => {
    setSelectedConversation({ id: member.id, username: member.username, prenom: member.prenom });
    fetchMessages(member.id);
    markMessagesAsRead(member.id);
  };

  useEffect(() => {
    const interval = setInterval(fetchMembers, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchAuthenticatedUser();
    fetchMembers();
  }, []);

  return (
    <div>
      <Header1 />
      <div className="flex h-screen">
        {/* Members Section */}
        <div className="w-1/4 bg-gray-200 p-4 overflow-y-auto">
          <h2 className="text-lg font-bold mb-4 flex items-center">
            <IoMdArrowDropright className="mr-2" /> Mes Membres
          </h2>
          {members.map((member) => (
            <div
              key={member.id}
              className={`p-2 mb-2 cursor-pointer rounded-md flex items-center space-x-3 ${
                selectedConversation?.id === member.id ? "bg-green-500 text-white" : "bg-white"
              }`}
              onClick={() => handleSelectConversation(member)}
            >
              {member.photo_profil ? (
                <img
                  // src={`http://localhost:8000/storage/${member.photo_profil}`}
                  src={`https://editions7.com/admin/public/storage/${member.photo_profil}`}

                  alt={`${member.prenom} ${member.username}`}
                  className="w-10 h-10 rounded-full object-cover"
                />
              ) : (
                <div className="w-10 h-10 flex items-center justify-center bg-gray-500 text-white font-bold rounded-full">
                  {getInitials(member.prenom, member.username)}
                </div>
              )}
              <div className="flex-1">
                <p className="font-semibold">{member.prenom} {member.username}</p>
              </div>
              {member.unreadCount > 0 && (
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                  {member.unreadCount}
                </span>
              )}
            </div>
          ))}
        </div>

        {/* Messages Section */}
        <div className="w-3/4 flex flex-col">
          {selectedConversation ? (
            <>
              <div className="bg-gray-300 p-4">
                <h2 className="text-lg font-bold">
                  Discussion avec {selectedConversation.prenom} {selectedConversation.username}
                </h2>
              </div>
              <div className="flex-1 overflow-y-auto p-4 space-y-4">
  {messages.length > 0 ? (
    messages.map((msg, idx) => (
      <div
        key={idx}
        className={`flex ${
          msg.sender_id === currentUserId ? "justify-end" : "justify-start"
        }`}
      >
        {/* Avatar for received messages */}
        {msg.sender_id !== currentUserId && (
          msg.sender_photo ? (
            <img
              // src={`http://localhost:8000/storage/${msg.sender_photo}`}
              src={`https://editions7.com/admin/public/storage/${msg.sender_photo}`}

              alt={`${msg.sender_prenom} ${msg.sender_username}`}
              className="w-10 h-10 rounded-full object-cover mr-2"
            />
          ) : (
            <div
              className="w-10 h-10 flex items-center justify-center bg-gray-500 text-white font-bold rounded-full mr-2"
              style={{ fontSize: "0.875rem" }}
            >
              {getInitials(msg.sender_prenom || "", msg.sender_username || "")}
            </div>
          )
        )}

        {/* Message content */}
        <div
          className={`max-w-xs p-3 rounded-lg ${
            msg.sender_id === currentUserId
              ? "bg-green-200 text-gray-800"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          {/* Sender name */}
          <p className="text-sm font-semibold mb-1">
            {msg.sender_id === currentUserId
              ? "Vous"
              : `${msg.sender_prenom || "Inconnu"} ${msg.sender_username || ""}`}
          </p>

          {/* Message content */}
          <p>{msg.content}</p>

          {/* Timestamp */}
          <p className="text-xs text-gray-500 mt-1 text-right">
            {new Date(msg.created_at).toLocaleString()}
          </p>
        </div>
      </div>
    ))
  ) : (
    <p>Aucun message dans cette discussion.</p>
  )}
</div>

              <div className="bg-gray-300 p-4 flex items-center">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="flex-1 p-2 border border-gray-300 rounded-md"
                  placeholder="Écrivez un message..."
                />
                <button
                  onClick={handleSendMessage}
                  className="ml-4 bg-green-500 text-white px-4 py-2 rounded-md"
                >
                  Envoyer
                </button>
              </div>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center">
              <p>Sélectionnez un membre pour afficher les messages.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatApp;

