import React, { useState } from 'react';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../key';

const Connexion = () => {
  const navigate = useNavigate();
  const [emailOrIdentifiant, setEmailOrIdentifiant] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleConnexionClick = async () => {
    if (!emailOrIdentifiant || !password) {
      setErrorMessage('Veuillez remplir tous les champs.');
      return;
    }

    try {
      // const response = await fetch('http://localhost:8000/api/login', {
        const response = await fetch(`${BASE_URL}/login`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_or_identifiant: emailOrIdentifiant, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('isLoggedIn', 'true'); // Store login state
        localStorage.setItem('token', data.token); // Store token
        localStorage.setItem('userRole', data.user.role); // Store role
        localStorage.setItem('username', data.user.username); // Store username

        // Redirect based on the role
       if (data.user.role === 'AUTEUR') {
  navigate('/mon-compte');
} else if (data.user.role === 'DIFFUSEUR') {
  navigate('/compte-diffuseur');
} else if (data.user.role === 'AUTEUR-DIFFUSEUR') {
  navigate('/compte-auteur-diffuseur');
}

        
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.error || 'Identifiant ou mot de passe incorrect.');
      }
    } catch (error) {
      setErrorMessage('Une erreur réseau est survenue. Veuillez réessayer.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-white py-16 px-4 md:px-0 max-w-2xl mx-auto">
      <h1 className="text-center text-2xl font-bold mb-16 uppercase">
        Connectez-vous à votre compte :
      </h1>
      <div className="space-y-8">
        {errorMessage && (
          <div className="bg-red-100 text-red-600 border border-red-400 p-4 rounded-lg mb-4">
            {errorMessage}
          </div>
        )}
        <div className="flex justify-between items-center">
          <label className="font-semibold text-lg text-left md:w-64">EMAIL OU IDENTIFIANT :</label>
          <input
            type="text"
            className="w-full md:w-2/3 border border-gray-300 p-3 rounded-lg"
            placeholder="Entrez votre email ou identifiant"
            value={emailOrIdentifiant}
            onChange={(e) => setEmailOrIdentifiant(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <label className="font-semibold text-lg text-left md:w-64">MOT DE PASSE :</label>
          <div className="relative w-full md:w-2/3">
            <input
              type={showPassword ? 'text' : 'password'}
              className="w-full border border-gray-300 p-3 rounded-lg"
              placeholder="Entrez votre mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              onClick={togglePasswordVisibility}
              className="absolute right-4 top-3 cursor-pointer text-2xl text-gray-500"
            >
              {showPassword ? <IoMdEye /> : <IoMdEyeOff />}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center w-full md:w-2/3 ml-56">
          <a href="#" className="text-blue-600 hover:underline text-sm">
            Mot de Passe perdu ?
          </a>
          <button
            className="bg-green-600 text-white font-bold py-3 px-8 rounded-lg text-lg hover:bg-green-500"
            onClick={handleConnexionClick}
          >
            Se connecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Connexion;
